import { RichTextInput } from "ra-input-rich-text";import React from "react";
import {
	AutocompleteInput,
	Create,
	Datagrid,
	Edit,
	ImageField,
	ImageInput,
	List,
	required,
	RichTextField,
	SelectField,
	SimpleForm,
	TextField,
	TextInput,
} from "react-admin";

export function EmployeeList(props) {
	return (
		<List {...props} perPage={25}>
			<Datagrid rowClick="edit">
				<SelectField
					source="category"
					label="Catégorie"
					choices={[
						{ id: "conseil", name: "Conseil de fondation" },
						{ id: "direction", name: "Direction" },
						{ id: "terrain", name: "Equipe de terrain" },
						{ id: "externe", name: "Collaborateurs externes" },
					]}
				/>
				<TextField source="name" label="Nom" />
				<RichTextField source="body" label="Contenu" />
			</Datagrid>
		</List>
	);
}

export const EmployeeCreate = (props) => {
	return (
		<Create title="Nouveau" {...props}>
			<SimpleForm>
				<AutocompleteInput
					source="category"
					label="Catégorie"
					choices={[
						{ id: "conseil", name: "Conseil de fondation" },
						{ id: "direction", name: "Direction" },
						{ id: "terrain", name: "Equipe de terrain" },
						{ id: "externe", name: "Collaborateurs externes" },
						{ id: "jura", name: "Arc jurassien" },
						{ id: "sudalpes", name: "Alpes du Sud" },
						{ id: "nordalpes", name: "Alpes du Nord" },
					]}
					validate={[required()]}
				/>
				<TextInput source="name" label="Nom" resettable />
				<RichTextInput source="body" label="Contenu" />
				<ImageInput source="pictures" label="Image" accept="image/*">
					<ImageField source="src" title="title" />
				</ImageInput>
			</SimpleForm>
		</Create>
	);
};

export const EmployeeEdit = (props) => {
	return (
		<Edit title="Modifier" {...props} undoable={false}>
			<SimpleForm>
				<AutocompleteInput
					source="category"
					label="Catégorie"
					choices={[
						{ id: "conseil", name: "Conseil de fondation" },
						{ id: "direction", name: "Direction" },
						{ id: "terrain", name: "Equipe de terrain" },
						{ id: "externe", name: "Collaborateurs externes" },
					]}
					validate={[required()]}
				/>
				<TextInput source="name" label="Nom" resettable />
				<RichTextInput source="body" label="Contenu" />
				<ImageInput source="pictures" label="Image" accept="image/*">
					<ImageField source="src" title="title" />
				</ImageInput>
			</SimpleForm>
		</Edit>
	);
};
