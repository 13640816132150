import { collection, getDocs } from "firebase/firestore";import React, { useEffect, useState } from "react";
import { db } from "../../../services/firebaseDatabase";
import HomepageNews from "../components/HomepageNews";

function HomepageNewsContainer() {
	const [news, setNews] = useState([]);
	const fetchNews = async () => {
		const data = await getDocs(collection(db, "news"));

		data.forEach((item) => {
			if (item.data().isPublished === true) {
				setNews((news) =>
					news.concat({ id: item.id, data: item.data() })
				);
			}
		});
	};

	useEffect(() => {
		fetchNews();

		// eslint-disable-next-line
	}, []);

	return <HomepageNews news={news} />;
}

export default HomepageNewsContainer;
