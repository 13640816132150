import { collection, getDocs } from "firebase/firestore";import React, { useEffect, useState } from "react";
import { db } from "../../../services/firebaseDatabase";
import PartenairesList from "../components/PartenairesList";

/**
 * Main component for the News page
 * @returns
 */
function PartenairesPage() {
	const [loading, setLoading] = useState(true);
	const [docs, setDocs] = useState([]);
	const fetchDocuments = async () => {
		const data = await getDocs(collection(db, "partenaires"));

		data.forEach((item) => {
			if (item.data().isPublished === true) {
				setDocs((docs) =>
					docs.concat({ id: item.id, data: item.data() })
				);
			}
		});
		setLoading(false);
	};

	useEffect(() => {
		fetchDocuments();
		// eslint-disable-next-line
	}, []);

	return (
		<PartenairesList
			documents={docs}
			titre="Nos Partenaires"
			loading={loading}
		/>
	);
}

export default PartenairesPage;
