import Administration from "../scenes/Administration";import ContactPage from "../scenes/Contact";
import Foundation from "../scenes/Foundation";
import HomePage from "../scenes/Homepage";
import Institute from "../scenes/Institute";
import JoiningPage from "../scenes/Joining";
import MentionsPage from "../scenes/Mentions";
import NewsPage from "../scenes/News";
import ShowNews from "../scenes/News/components/ShowNews";
import PerformancePage from "../scenes/Performance";
import ProjectsPage from "../scenes/Projects";
import CanovisPage from "../scenes/Projects/Canovis";
import DaphnisPage from "../scenes/Projects/Daphnis";
import ProxyloupPage from "../scenes/Projects/Proxyloup";
import WolfFencesPage from "../scenes/Projects/WolfFences";
import ResourcesPage from "../scenes/Resources";
import BibliographiePage from "../scenes/Resources/Bibliographie";
import CDPPage from "../scenes/Resources/CDPNews";
import ShowDocuments from "../scenes/Resources/components/ShowDocuments";
import DocumentairesPage from "../scenes/Resources/Documentaires";
import DocumentsPage from "../scenes/Resources/Documents";
import PartenairesPage from "../scenes/Resources/Partenaires";
import PastoralismePage from "../scenes/Resources/Pastoralisme";
import PressePage from "../scenes/Resources/Presse";
import SitesPage from "../scenes/Resources/Sites";
import VideoPage from "../scenes/Resources/Videotheque";
import ShowVideo from "../scenes/Resources/Videotheque/ShowVideo";
import TeamPage from "../scenes/Team";

const routing = [
	{
		path: "/",
		component: <HomePage />,
	},
	{
		path: "/administration/*",
		component: <Administration />,
	},
	{
		path: "/foundation",
		component: <Foundation />,
	},
	{
		path: "/institute",
		component: <Institute />,
	},
	{
		path: "/news",
		component: <NewsPage />,
	},
	{
		path: "/news/:id",
		component: <ShowNews />,
	},
	{
		path: "/projects",
		component: <ProjectsPage />,
	},
	{
		path: "/performance",
		component: <PerformancePage />,
	},

	{
		path: "/joining",
		component: <JoiningPage />,
	},
	{
		path: "/team",
		component: <TeamPage />,
	},

	{
		path: "/contact",
		component: <ContactPage />,
	},
	{
		path: "/mentions",
		component: <MentionsPage />,
	},
	{
		path: "/proxyloup",
		component: <ProxyloupPage />,
	},
	{
		path: "/loup_cloture",
		component: <WolfFencesPage />,
	},
	{
		path: "/daphnis",
		component: <DaphnisPage />,
	},
	{
		path: "/canovis",
		component: <CanovisPage />,
	},
	{
		path: "/resources",
		component: <ResourcesPage />,
	},
	{
		path: "/resources/:id",
		component: <ShowDocuments />,
	},
	{
		path: "/documents",
		component: <DocumentsPage />,
	},
	{
		path: "/pastoralisme",
		component: <PastoralismePage />,
	},
	{
		path: "/cdp_news",
		component: <CDPPage />,
	},
	{
		path: "/presse",
		component: <PressePage />,
	},
	{
		path: "/documentaires",
		component: <DocumentairesPage />,
	},
	{
		path: "/videotheque",
		component: <VideoPage />,
	},
	{
		path: "/videotheque/:id",
		component: <ShowVideo />,
	},
	{
		path: "/bibliographie",
		component: <BibliographiePage />,
	},
	{
		path: "/partenaires",
		component: <PartenairesPage />,
	},
	{
		path: "/sites",
		component: <SitesPage />,
	},
];
export default routing;
