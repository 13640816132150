import { Create, Edit, SimpleForm, TextInput } from "ra-ui-materialui";
import * as React from "react";
import { Datagrid, List, TextField } from "react-admin";

export const YoutubeList = (props) => (
	<List {...props}>
		<Datagrid rowClick="edit">
			<TextField source="link" label="Lien Youtube" />
		</Datagrid>
	</List>
);

export const YoutubeCreate = (props) => (
	<Create {...props}>
		<SimpleForm>
			<TextInput source="link" label="Lien Youtube" fullWidth />
		</SimpleForm>
	</Create>
);

export const YoutubeEdit = (props) => (
	<Edit {...props} undoable={false}>
		<SimpleForm>
			<TextInput source="link" label="Lien Youtube" fullWidth />
			{/* <TextInput source="indice" /> */}
		</SimpleForm>
	</Edit>
);
