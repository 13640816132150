import {	Container,
	Grid,
	IconButton,
	makeStyles,
	Typography,
} from "@material-ui/core";
import { ArrowBackRounded, CloudDownloadRounded } from "@material-ui/icons";
import { collection, getDocs } from "firebase/firestore";

import React, { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import Image from "../../../components/Image";
import LightTooltip from "../../../components/LightToolTips";
import { db } from "../../../services/firebaseDatabase";
import calculateDate from "../../../utils/dateToText";

const useStyle = makeStyles((theme) => ({
	root: { marginBottom: theme.spacing(6), marginTop: theme.spacing(6) },
	icon: { fontSize: theme.spacing(8), color: theme.palette.primary.main },
	title: {
		fontSize: "4rem",
		fontWeight: "100",
		[theme.breakpoints.down("sm")]: {
			fontSize: "3rem",
		},
	},
}));

function ShowDocuments() {
	const classes = useStyle();

	const { id } = useParams();
	const [document, setDoc] = useState();
	const fetchDocuments = async () => {
		const data = await getDocs(collection(db, "documents"));

		data.forEach((item) => {
			if (item.id === id) setDoc(item.data());
		});
	};
	useEffect(() => {
		fetchDocuments();
		// eslint-disable-next-line
	}, []);

	return (
		<Container className={classes.root}>
			{document ? (
				<>
					<Grid container direction="row" alignItems="center">
						<Grid item md={1}>
							<LightTooltip
								title="Retour aux ressources"
								enterDelay={1000}
							>
								<IconButton>
									<Link to={"/resources"}>
										<ArrowBackRounded
											className={classes.icon}
										/>
									</Link>
								</IconButton>
							</LightTooltip>
						</Grid>
						<Grid item md={11}>
							<Typography className={classes.title}>
								{document.title}
							</Typography>
						</Grid>
					</Grid>
					<Grid container spacing={8} className={classes.root}>
						{document.pictures && (
							<Grid
								item
								container
								md
								xs={12}
								justifyContent="center"
								direction="column"
								alignItems="flex-end"
							>
								<Image
									src={document.pictures.src}
									alt={document.pictures.title}
									width="100%"
								/>
							</Grid>
						)}
						<Grid
							item
							container
							md
							xs={12}
							justifyContent="center"
							direction="column"
						>
							<Typography align="justify" variant="h6">
								Description :
							</Typography>
							<Typography
								align="justify"
								dangerouslySetInnerHTML={{
									__html: document.description,
								}}
							></Typography>
							{document.published && (
								<>
									<Typography align="justify" variant="h6">
										Date de publication :
									</Typography>
									<Typography align="justify">
										{calculateDate(document.published)}
									</Typography>
								</>
							)}

							{(document.files || document.id_gdrive) && (
								<Grid
									item
									container
									alignItems="center"
									direction="row"
								>
									<Grid item xs={3} align="right">
										<LightTooltip
											title="Télécharger"
											enterDelay={1000}
										>
											<IconButton
												onClick={() =>
													window.open(
														document.files
															? document.files.src
															: `https://drive.google.com/uc?export=download&id=${document.id_gdrive}`
													)
												}
											>
												<CloudDownloadRounded
													className={classes.icon}
												/>
											</IconButton>
										</LightTooltip>
									</Grid>
									<Grid
										item
										container
										xs={9}
										alignItems="flex-start"
										justifyContent="center"
										direction="column"
									>
										<Typography
											onClick={() =>
												window.open(
													document.files
														? document.files.src
														: `https://drive.google.com/uc?export=download&id=${document.id_gdrive}`
												)
											}
										>
											Télécharger le document
										</Typography>
									</Grid>
								</Grid>
							)}
						</Grid>
					</Grid>
				</>
			) : (
				<Grid container spacing={6}>
					<Grid item xs align="center">
						<Typography variant="h5">Chargement...</Typography>
					</Grid>
				</Grid>
			)}
		</Container>
	);
}

export default ShowDocuments;
