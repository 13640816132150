import {	Container,
	Grid,
	IconButton,
	makeStyles,
	Typography,
} from "@material-ui/core";
import { ArrowBackRounded } from "@material-ui/icons";
import { collection, getDocs } from "firebase/firestore";

import React, { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import Image from "../../../components/Image";
import LightTooltip from "../../../components/LightToolTips";
import ReferencesSection from "../../../components/Page/ReferencesSection";
import { db } from "../../../services/firebaseDatabase";
import calculateDate from "../../../utils/dateToText";

const useStyle = makeStyles((theme) => ({
	root: { marginBottom: theme.spacing(6), marginTop: theme.spacing(6) },
	date: { fontStyle: "italic", color: theme.palette.primary.main },
	icon: {
		fontSize: theme.spacing(8),
		color: theme.palette.primary.main,
		[theme.breakpoints.down("sm")]: {
			fontSize: theme.spacing(6),
		},
	},
	title: {
		fontSize: "4rem",
		fontWeight: "100",
		[theme.breakpoints.down("sm")]: {
			fontSize: "3rem",
		},
	},
	body: { marginBottom: theme.spacing(6) },
}));

function ShowNews() {
	const classes = useStyle();
	const { id } = useParams();

	const [news, setNews] = useState();
	const fetchNews = async () => {
		const data = await getDocs(collection(db, "news"));

		data.forEach((item) => {
			if (item.id === id) setNews(item.data());
		});
	};
	useEffect(() => {
		fetchNews();

		// eslint-disable-next-line
	}, []);

	return (
		<Container className={classes.root}>
			{news && (
				<>
					<Grid container direction="row" alignItems="center">
						<Grid item md={1}>
							<LightTooltip
								title="Retour aux actualités"
								enterDelay={1000}
							>
								<IconButton>
									<Link to={"/news"}>
										<ArrowBackRounded
											className={classes.icon}
										/>
									</Link>
								</IconButton>
							</LightTooltip>
						</Grid>
						<Grid item md={11}>
							<Typography className={classes.title}>
								{news.title}
							</Typography>
							<Typography variant="h6" className={classes.date}>
								{calculateDate(news.published)}
							</Typography>
						</Grid>
					</Grid>

					<Grid container spacing={8} className={classes.root}>
						<Grid
							item
							container
							md={6}
							xs={12}
							alignItems="flex-end"
							justifyContent="center"
							direction="column"
						>
							<Typography
								className={classes.body}
								align="justify"
								dangerouslySetInnerHTML={{
									__html: news.body,
								}}
							></Typography>
							<ReferencesSection links={news.documents_id} />
						</Grid>
						<Grid
							item
							container
							md={6}
							xs={12}
							justifyContent="center"
							direction="column"
						>
							<Image
								src={news.pictures.src}
								alt={news.pictures.title}
								width="100%"
							/>
						</Grid>
					</Grid>
				</>
			)}
		</Container>
	);
}

export default ShowNews;
