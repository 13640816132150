import { Grid, Slide, useScrollTrigger } from "@material-ui/core";import AppBar from "@material-ui/core/AppBar";
import IconButton from "@material-ui/core/IconButton";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";
import { HomeRounded } from "@material-ui/icons";
import MenuIcon from "@material-ui/icons/Menu";
import React, { useState } from "react";
import { Link } from "react-router-dom";
import MenuDrawer from "./Menu/MenuDrawer";

const useStyles = makeStyles((theme) => ({
	topbar: {
		paddingLeft: 0,
		paddingRight: 0,
		flexGrow: 1,
		height: theme.mixins.toolbar.minHeight,
		minHeight: 0,
		zIndex: theme.zIndex.drawer + 1000,
		background: `linear-gradient(45deg, ${theme.palette.primary.main} 30%, ${theme.palette.primary.dark} 90%)`,
	},
	textColor: {
		color: theme.palette.secondary.light,
		textDecoration: "none",
	},
	homeIcon: {
		color: theme.palette.secondary.light,
		textDecoration: "none",
		marginLeft: "8px",
	},
	menuIcon: {
		color: theme.palette.secondary.light,
		textDecoration: "none",
		marginRight: "8px",
	},
}));

function HideOnScroll(props) {
	const { children, window } = props;
	// Note that you normally won't need to set the window ref as useScrollTrigger
	// will default to window.
	// This is only being set here because the demo is in an iframe.
	const trigger = useScrollTrigger({ target: window ? window() : undefined });

	return (
		<Slide appear={false} direction="down" in={!trigger}>
			{children}
		</Slide>
	);
}

function TopBar(props) {
	const classes = useStyles();
	const [openDrawer, setOpenDrawer] = useState(false);

	const toggleDrawer = () => (event) => {
		if (
			event.type === "keydown" &&
			(event.key === "Tab" || event.key === "Shift")
		) {
			return;
		}
		setOpenDrawer(!openDrawer);
	};

	return (
		<div className={classes.topbar}>
			<HideOnScroll {...props}>
				<AppBar className={classes.topbar}>
					<Toolbar className={classes.topbar}>
						<Grid container>
							<Grid container item xs alignItems="center">
								<Grid item>
									<Link to="/">
										<IconButton
											className={classes.homeIcon}
										>
											<HomeRounded />
										</IconButton>
									</Link>
								</Grid>
								<Grid item>
									<Link to="/" className={classes.textColor}>
										<Typography variant="h6">
											IPRA-FJML
										</Typography>
									</Link>
								</Grid>
							</Grid>
							<Grid
								container
								item
								xs
								alignItems="center"
								justifyContent="flex-end"
							>
								<Grid item>
									<Typography
										variant="h6"
										className={classes.textColor}
										onClick={toggleDrawer()}
									>
										Menu
									</Typography>
								</Grid>

								<Grid item>
									<IconButton
										className={classes.menuIcon}
										onClick={toggleDrawer()}
									>
										<MenuIcon />
									</IconButton>
								</Grid>
							</Grid>
						</Grid>
					</Toolbar>

					<MenuDrawer open={openDrawer} onClose={toggleDrawer()} />
				</AppBar>
			</HideOnScroll>
		</div>
	);
}

export default TopBar;
