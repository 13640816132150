import { Grid, Typography } from "@material-ui/core";
import React from "react";
import ReferencesSection from "./ReferencesSection";

function SectionHeader({ element }) {
	return (
		<>
			{element && (
				<Grid item container spacing={6} id={element.title}>
					{element.title && (
						<Grid item xs={12}>
							<Typography variant="h3">
								{element.title}
							</Typography>
						</Grid>
					)}
					{element.body && (
						<Grid item xs={12}>
							<Typography
								align="justify"
								dangerouslySetInnerHTML={{
									__html: element.body,
								}}
							></Typography>
						</Grid>
					)}
					<ReferencesSection links={element.documents_id} />
				</Grid>
			)}
		</>
	);
}

export default SectionHeader;
