import {	Button,
	Container,
	Grid,
	makeStyles,
	Typography,
} from "@material-ui/core";
import { collection, getDocs } from "firebase/firestore";

import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import ProjectsTeaser from "../../../components/Teaser/ProjectsTeaser";
import { db } from "../../../services/firebaseDatabase";

const useStyle = makeStyles((theme) => ({
	body: {
		paddingTop: theme.spacing(6),
		paddingBottom: theme.spacing(6),
		minHeight: "60vh",
	},
	title: { textAlign: "center" },
	button: {
		background: `linear-gradient(45deg, ${theme.palette.primary.main} 30%, ${theme.palette.primary.dark} 90%)`,
		color: theme.palette.secondary.main,
	},
}));

/**
 * Component to display projects section of homepage
 */
function HomepageProjects() {
	const classes = useStyle();
	const history = useNavigate();

	const [content, setContent] = useState([]);
	const fetchContent = async () => {
		const data = await getDocs(collection(db, "projets"));

		data.forEach((item) => {
			setContent((content) => content.concat(item.data()));
		});
	};
	useEffect(() => {
		fetchContent();

		//eslint-disable-next-line
	}, []);

	content.sort(function (a, b) {
		return a.order - b.order;
	});

	return (
		<div className={classes.body}>
			<Container>
				<Grid container>
					<Grid item xs={12}>
						<Typography variant="h4" className={classes.title}>
							Projets de recherche
						</Typography>
					</Grid>
					<Grid
						container
						direction="row"
						justifyContent="space-around"
						alignItems="center"
					>
						{content.length > 0 ? (
							content.slice(0, 3).map((project, index) => {
								return (
									<Grid item key={index}>
										<ProjectsTeaser
											title={project.title}
											body={project.body}
											image={project.pictures.src}
											link={project.link}
										/>
									</Grid>
								);
							})
						) : (
							<Typography>Chargement...</Typography>
						)}
					</Grid>

					<Grid item xs={12} align="center">
						<Button
							className={classes.button}
							variant="contained"
							onClick={() => {
								history("/projects");
							}}
						>
							Voir plus
						</Button>
					</Grid>
				</Grid>
			</Container>
		</div>
	);
}

export default HomepageProjects;
