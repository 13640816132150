import React from "react";
import {
	BooleanField,
	BooleanInput,
	Create,
	Datagrid,
	Edit,
	ImageField,
	ImageInput,
	List,
	required,
	SimpleForm,
	TextField,
	TextInput,
} from "react-admin";

export const PartenairesList = (props) => (
	<List {...props}>
		<Datagrid rowClick="edit">
			<BooleanField source="isPublished" label="Publié" />
			<TextField source="name" label="Nom" />

			<TextField source="description" label="Description" />
			<TextField source="link" label="Lien du site" />
		</Datagrid>
	</List>
);

export const PartenairesCreate = (props) => {
	return (
		<Create title="Nouveau Document" {...props}>
			<SimpleForm>
				<BooleanInput
					label="Publié"
					source="isPublished"
					initialValue={true}
				/>
				<TextInput
					source="name"
					label="Nom"
					resettable
					validate={[required()]}
				/>
				<ImageInput
					source="pictures"
					label="Image de l'aperçu"
					accept="image/*"
				>
					<ImageField source="src" title="title" />
				</ImageInput>
				<TextInput
					source="description"
					label="Description"
					resettable
				/>
				<TextInput source="link" label="Lien du site" resettable />
			</SimpleForm>
		</Create>
	);
};

export const PartenairesEdit = (props) => {
	return (
		<Edit title="Modifier le document" {...props} undoable={false}>
			<SimpleForm>
				<BooleanInput
					label="Publié"
					source="isPublished"
					initialValue={true}
				/>
				<TextInput
					source="name"
					label="Nom"
					resettable
					validate={[required()]}
				/>
				<ImageInput
					source="pictures"
					label="Image de l'aperçu"
					accept="image/*"
				>
					<ImageField source="src" title="title" />
				</ImageInput>
				<TextInput
					source="description"
					label="Description"
					resettable
				/>
				<TextInput source="link" label="Lien du site" resettable />
			</SimpleForm>
		</Edit>
	);
};
