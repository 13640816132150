import {	Container,
	Divider,
	Grid,
	makeStyles,
	Typography,
} from "@material-ui/core";
import { collection, getDocs } from "firebase/firestore";

import React, { useEffect, useState } from "react";
import DownloadFile from "../../components/DownloadFile";
import HeaderPage from "../../components/Page/HeaderPage";
import SectionHeader from "../../components/Page/SectionHeader";
import SectionImageLeft from "../../components/Page/SectionImageLeft";
import SectionImageRight from "../../components/Page/SectionImageRight";
import { db } from "../../services/firebaseDatabase";

const useStyle = makeStyles((theme) => ({
	root: { marginBottom: theme.spacing(6), marginTop: theme.spacing(6) },
}));

/**
 * Main component for the Foundation page
 * @returns
 */
function Foundation() {
	const classes = useStyle();

	const [content, setContent] = useState([]);
	const fetchContent = async () => {
		const data = await getDocs(collection(db, "FJML"));

		data.forEach((item) => {
			setContent((content) => content.concat(item.data()));
		});
	};
	useEffect(() => {
		fetchContent();

		// eslint-disable-next-line
	}, []);

	const foundation = content.find((c) => c.indice === "foundation");
	const foundation2 = content.find((c) => c.indice === "foundation2");
	const activity = content.find((c) => c.indice === "activity");
	const search = content.find((c) => c.indice === "search");
	const formation = content.find((c) => c.indice === "formation");
	const information = content.find((c) => c.indice === "information");
	const supporting = content.find((c) => c.indice === "supporting");

	return (
		<>
			{content.length > 0 ? (
				<>
					<Container className={classes.root}>
						<Grid container spacing={6}>
							<HeaderPage element={foundation} />
							<SectionImageRight element={foundation2} />
							<Grid item container md={6} xs={12}>
								<DownloadFile
									title={"STATUTS DE LA FONDATION"}
									subtitle={"Document du 12 avril 2017"}
									file={
										"/files/170412-Statuts-de-la-Fondation-FJML.pdf"
									}
								/>
							</Grid>
							<Grid item container md={6} xs={12}>
								<DownloadFile
									title={
										"INSCRIPTION AU REGISTRE DU COMMERCE"
									}
									subtitle={"Document du 23 mai 2017"}
									file={
										"/files/170523-Inscription-Registre-du-Commerce-FJML.pdf"
									}
								/>
							</Grid>
						</Grid>
						<Divider className={classes.root} />
						<Grid container spacing={6}>
							<SectionHeader element={activity} />
							<SectionImageRight element={search} />
							<SectionImageLeft element={formation} />
							<SectionImageRight element={information} />
						</Grid>
						<Divider className={classes.root} />
						<Grid container spacing={6}>
							<SectionHeader element={supporting} />
							<Grid container className={classes.root}>
								<Grid
									item
									xs={12}
									md={6}
									container
									direction="column"
									justifyContent="space-around"
									alignItems="center"
									spacing={6}
								>
									<DownloadFile
										title={"Virement bancaire (en CHF)"}
										subtitle={
											<>
												<b>
													IBAN : CH04 8080 8002 6401
													2168 5{" "}
												</b>
												<br />
												Merci d’indiquer “don” comme
												motif du virement
											</>
										}
										file={
											"/files/Coordonnées-compte-courant-CHF4582.pdf"
										}
									/>
									<DownloadFile
										title={"Virement bancaire (en EUR)"}
										subtitle={
											<>
												<b>
													IBAN : CH17 8080 8007 9560
													6793 5{" "}
												</b>
												<br />
												Merci d’indiquer “don” comme
												motif du virement
											</>
										}
										file={
											"/files/Coordonnées-compte-courant-EUR4581.pdf"
										}
									/>
								</Grid>
								<Grid
									item
									xs={12}
									md={6}
									container
									direction="column"
									justifyContent="center"
									alignItems="center"
								>
									<Typography variant="h6">
										Virement Paypal
									</Typography>
									<Typography>Bientôt disponible</Typography>

									{/* <form
								action="https://www.paypal.com/donate"
								method="post"
								target="_top"
							>
								<input
									type="hidden"
									name="hosted_button_id"
									value="36FCEM64CQ6WN"
								/>
								<input
									type="image"
									src="https://www.paypalobjects.com/fr_FR/FR/i/btn/btn_donateCC_LG.gif"
									border="0"
									name="submit"
									title="PayPal - The safer, easier way to pay online!"
									alt="Donate with PayPal button"
								/>
								<img
									alt=""
									border="0"
									src="https://www.paypal.com/fr_FR/i/scr/pixel.gif"
									width="1"
									height="1"
								/>
							</form> */}
								</Grid>
							</Grid>
						</Grid>
					</Container>
				</>
			) : (
				<Container className={classes.root}>
					<Grid container spacing={6}>
						<Grid item xs align="center">
							<Typography variant="h5">Chargement...</Typography>
						</Grid>
					</Grid>
				</Container>
			)}
		</>
	);
}

export default Foundation;
