import {	Container,
	Divider,
	Grid,
	makeStyles,
	Typography,
} from "@material-ui/core";
import { collection, getDocs } from "firebase/firestore";

import React, { useEffect, useState } from "react";
import HeaderPage from "../../components/Page/HeaderPage";
import SectionHeader from "../../components/Page/SectionHeader";
import SectionImageLeft from "../../components/Page/SectionImageLeft";
import SectionImageRight from "../../components/Page/SectionImageRight";
import { db } from "../../services/firebaseDatabase";

const useStyle = makeStyles((theme) => ({
	root: { marginBottom: theme.spacing(6), marginTop: theme.spacing(6) },
}));

/**
 * Main component for the Institute page
 * @returns
 */
function Institute() {
	const classes = useStyle();

	const [content, setContent] = useState([]);
	const fetchContent = async () => {
		const data = await getDocs(collection(db, "IPRA"));

		data.forEach((item) => {
			setContent((content) => content.concat(item.data()));
		});
	};
	useEffect(() => {
		fetchContent();

		// eslint-disable-next-line
	}, []);

	const institute = content.find((c) => c.indice === "institute");
	const history = content.find((c) => c.indice === "history");
	const step = content.find((c) => c.indice === "step");
	const scientific = content.find((c) => c.indice === "scientific");
	const methode = content.find((c) => c.indice === "methode");
	const axis = content.find((c) => c.indice === "axis");

	return (
		<>
			{content.length > 0 ? (
				<>
					<Container className={classes.root}>
						<Grid container spacing={6}>
							<HeaderPage element={institute} />
							<SectionImageRight element={history} />
							<SectionImageLeft element={step} />
						</Grid>
						<Divider className={classes.root} />
						<Grid container spacing={6}>
							<SectionHeader element={scientific} />
							<SectionImageRight element={methode} />
							<SectionImageLeft element={axis} />
						</Grid>
					</Container>
				</>
			) : (
				<Container className={classes.root}>
					<Grid container spacing={6}>
						<Grid item xs align="center">
							<Typography variant="h5">Chargement...</Typography>
						</Grid>
					</Grid>
				</Container>
			)}
		</>
	);
}

export default Institute;
