import {	Container,
	Grid,
	IconButton,
	makeStyles,
	Typography,
} from "@material-ui/core";
import YouTubeIcon from "@material-ui/icons/YouTube";
import clsx from "clsx";
import { collection, getDocs } from "firebase/firestore";

import React, { useEffect, useState } from "react";
import ReactPlayer from "react-player";
import LightTooltip from "../../../components/LightToolTips";
import { db } from "../../../services/firebaseDatabase";

const useStyle = makeStyles((theme) => ({
	bodyBanner: {
		width: "100%",
		minHeight: "40vh",
		paddingTop: theme.spacing(6),
		paddingBottom: theme.spacing(6),
		position: "relative",
		backgroundColor: theme.palette.primary.dark,
	},
	text: {
		fontSize: theme.spacing(3),
		textAlign: "center",
	},
	icon: {
		fontSize: theme.spacing(15),
	},
	textColor: {
		color: theme.palette.secondary.light,
	},
}));

/**
 * Component to display homepage banner

 */
function HomepageYoutube() {
	const classes = useStyle();

	const [content, setContent] = useState([]);
	const fetchContent = async () => {
		const data = await getDocs(collection(db, "youtube"));

		data.forEach((item) => {
			setContent((content) => content.concat(item.data()));
		});
	};
	useEffect(() => {
		fetchContent();

		// eslint-disable-next-line
	}, []);

	const video = content.find((c) => c.indice === "homepage");

	return (
		<Grid className={classes.bodyBanner} container>
			<Container>
				<Grid container spacing={6}>
					<Grid
						item
						container
						md={6}
						xs={12}
						alignItems="center"
						justifyContent="center"
						direction="column"
					>
						<Typography
							className={clsx(classes.text, classes.textColor)}
						>
							Suivez nous sur notre chaîne Youtube
						</Typography>
						<LightTooltip
							title="Vers notre chaîne"
							enterdelay={1000}
						>
							<IconButton
								onClick={() =>
									window.open(
										"https://www.youtube.com/channel/UCwppVHHSvUVH2XHeUlBc21w"
									)
								}
							>
								<YouTubeIcon className={classes.icon} />
							</IconButton>
						</LightTooltip>
					</Grid>
					<Grid item md={6} xs={12}>
						<Typography variant="h6" className={classes.textColor}>
							<i>Dernière vidéo</i>
						</Typography>
						{video && <ReactPlayer url={video.link} width="100%" />}
					</Grid>
				</Grid>
			</Container>
		</Grid>
	);
}

export default HomepageYoutube;
