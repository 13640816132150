import { makeStyles } from "@material-ui/core";import { createBrowserHistory } from "history";
import React from "react";
import { Route, BrowserRouter as Router, Routes } from "react-router-dom";
import Footer from "../components/Footer/Footer";
import ScrollToTop from "../components/ScrollToTop";
import TopBar from "../components/Topbar";
import NotFoundPage from "../scenes/NotFound";
import routing from "./routing";

const routeStyles = makeStyles((theme) => ({
	root: {
		minHeight: "100vh",
	},
	content: {
		minHeight: `calc(100vh - ${theme.mixins.toolbar.minHeight}px)`,
		overflow: "hidden",
	},
}));

const history = createBrowserHistory();

/**
 * Component for the routing of the app
 * @returns
 */
function NavRoutes() {
	const classes = routeStyles();

	return (
		<Router history={history}>
			<ScrollToTop />

			<div className={classes.root}>
				<TopBar />
				<div className={classes.content}>
					<Routes>
						{routing.map((route) => (
							<Route
								key={route.path}
								exact
								path={route.path}
								element={route.component}
							/>
						))}
						<Route element={<NotFoundPage />} />
					</Routes>
				</div>
			</div>
			<Footer />
		</Router>
	);
}

export default NavRoutes;
