import { collection, getDocs } from "firebase/firestore";import React, { useEffect, useState } from "react";
import { db } from "../../services/firebaseDatabase";
import NewsList from "./components/NewsList";
/**
 * Main component for the News page
 * @returns
 */
function NewsPage() {
	const [news, setNews] = useState([]);
	const fetchNews = async () => {
		const data = await getDocs(collection(db, "news"));

		data.forEach((item) => {
			if (item.data().isPublished === true) {
				setNews((news) =>
					news.concat({ id: item.id, data: item.data() })
				);
			}
		});
	};

	useEffect(() => {
		fetchNews();

		// eslint-disable-next-line
	}, []);

	return <NewsList news={news} />;
}

export default NewsPage;
