import {	Container,
	Grid,
	IconButton,
	makeStyles,
	Typography,
} from "@material-ui/core";
import { ArrowBackRounded } from "@material-ui/icons";
import React from "react";
import { Link } from "react-router-dom";
import LightTooltip from "../../../components/LightToolTips";
import DocumentsTeaser from "../../../components/Teaser/DocumentsTeaser";

const useStyle = makeStyles((theme) => ({
	root: { marginBottom: theme.spacing(6), marginTop: theme.spacing(6) },
	icon: { fontSize: theme.spacing(8), color: theme.palette.primary.main },
	title: {
		fontSize: "6rem",
		fontWeight: "100",
		[theme.breakpoints.down("sm")]: {
			fontSize: "3rem",
		},
	},
}));
/**
 * Main component for the News page
 * @returns
 */
function DocumentsList({ documents, titre, loading }) {
	const classes = useStyle();
	documents.sort(function (a, b) {
		return new Date(b.data.published) - new Date(a.data.published);
	});
	return (
		<Container className={classes.root}>
			<Grid container direction="row" alignItems="center">
				<Grid item md={1}>
					<LightTooltip
						title="Retour aux ressources"
						enterDelay={1000}
					>
						<IconButton>
							<Link to={"/resources"}>
								<ArrowBackRounded className={classes.icon} />
							</Link>
						</IconButton>
					</LightTooltip>
				</Grid>
				<Grid item md={11}>
					<Typography className={classes.title}>{titre}</Typography>
				</Grid>
			</Grid>

			<Grid
				container
				direction="row"
				justifyContent="space-around"
				alignItems="center"
			>
				{documents.length > 0 ? (
					documents.map((document, index) => {
						return (
							<Grid item key={index}>
								<DocumentsTeaser document={document} />
							</Grid>
						);
					})
				) : loading ? (
					<Typography>Chargement...</Typography>
				) : (
					<Typography>
						Il n'y a pas de document pour l'instant.
					</Typography>
				)}
			</Grid>
		</Container>
	);
}

export default DocumentsList;
