import { Grid, makeStyles, Typography } from "@material-ui/core";
import React from "react";

const useStyle = makeStyles((theme) => ({
	subTitle: {
		fontWeight: "Bold",
		marginBottom: theme.spacing(2),
		color: theme.palette.primary.main,
	},
}));

function ContactSection({ title, body }) {
	const classes = useStyle();
	return (
		<Grid item xs={12} md>
			<Typography className={classes.subTitle}>{title}</Typography>
			<Typography
				dangerouslySetInnerHTML={{
					__html: body,
				}}
			></Typography>
		</Grid>
	);
}

export default ContactSection;
