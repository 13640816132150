import { Container, Grid, makeStyles } from "@material-ui/core";import React from "react";
import HeaderPage from "../../components/Page/HeaderPage";
import MediaTeaser from "../../components/Teaser/MediaTeaser";
import HomepageYoutube from "../Homepage/components/HomepageYoutube";

const DOCUMENTS =
	process.env.PUBLIC_URL + "/img/resources/2018-IPRA_Prestations.jpg";
const PASTORALISME =
	process.env.PUBLIC_URL + "/img/resources/pastoralisme-quesaco.jpg";
const CDP = process.env.PUBLIC_URL + "/img/resources/CDPnews10-Spring2014.jpg";
const PRESSE =
	process.env.PUBLIC_URL +
	"/img/resources/La-nuit-certains-chiens-de-berger-jourent-avec-les-loups_LeMatin_20201206-300x232.jpg";
const DOCUMENTAIRES =
	process.env.PUBLIC_URL + "/img/resources/2018_Animals-with-Cameras_EN.jpg";
const PHOTO = process.env.PUBLIC_URL + "/img/resources/303.jpg";
const BIBLIOGRAPHIE = process.env.PUBLIC_URL + "/img/resources/le-loup.jpg";
const PARTENAIRE = process.env.PUBLIC_URL + "/img/resources/logo-pnm.jpg";
const SITES = process.env.PUBLIC_URL + "/img/resources/sites2.jpg";

const useStyle = makeStyles((theme) => ({
	root: { marginBottom: theme.spacing(6), marginTop: theme.spacing(6) },
}));

/**
 * Main component for the resources page
 * @returns
 */
function ResourcesPage() {
	const classes = useStyle();
	return (
		<>
			<Container className={classes.root}>
				<HeaderPage element={{ title: "Ressources & Références" }} />
				<Grid
					container
					direction="row"
					justifyContent="space-evenly"
					alignItems="center"
				>
					<Grid item>
						<MediaTeaser
							title={"Pastoralisme et Loups"}
							image={PASTORALISME}
							link={"/pastoralisme"}
						/>
					</Grid>
					<Grid item>
						<MediaTeaser
							title={"Vidéothèque"}
							image={PHOTO}
							link={"/videotheque"}
						/>
					</Grid>
					<Grid item>
						<MediaTeaser
							title={"Documents IPRA"}
							image={DOCUMENTS}
							link={"/documents"}
						/>
					</Grid>
					<Grid item>
						<MediaTeaser
							title={"Presse"}
							image={PRESSE}
							link={"/presse"}
						/>
					</Grid>
					<Grid item>
						<MediaTeaser
							title={"Documentaires"}
							image={DOCUMENTAIRES}
							link={"/documentaires"}
						/>
					</Grid>
					<Grid item>
						<MediaTeaser
							title={"Revue CDP News"}
							body={<></>}
							image={CDP}
							link={"/cdp_news"}
						/>
					</Grid>
					<Grid item>
						<MediaTeaser
							title={"Bibliographie"}
							image={BIBLIOGRAPHIE}
							link={"/bibliographie"}
						/>
					</Grid>
					<Grid item>
						<MediaTeaser
							title={"Sites Web"}
							image={SITES}
							link={"/sites"}
						/>
					</Grid>
					<Grid item>
						<MediaTeaser
							title={"Nos partenaires"}
							image={PARTENAIRE}
							link={"/partenaires"}
						/>
					</Grid>
				</Grid>
			</Container>
			<HomepageYoutube />
		</>
	);
}

export default ResourcesPage;
