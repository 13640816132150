import {	Container,
	Divider,
	Grid,
	makeStyles,
	Typography,
} from "@material-ui/core";
import { collection, getDocs } from "firebase/firestore";

import React, { useEffect, useState } from "react";
import HeaderPage from "../../../components/Page/HeaderPage";
import SectionHeader from "../../../components/Page/SectionHeader";
import SectionImageLeft from "../../../components/Page/SectionImageLeft";
import SectionImageRight from "../../../components/Page/SectionImageRight";
import SectionImageTop from "../../../components/Page/SectionImageTop";
import { db } from "../../../services/firebaseDatabase";

const useStyle = makeStyles((theme) => ({
	root: { marginBottom: theme.spacing(6), marginTop: theme.spacing(6) },
}));

/**
 * Main component for the News page
 * @returns
 */
function PastoralismePage() {
	const classes = useStyle();
	const [content, setContent] = useState([]);
	const fetchContent = async () => {
		const data = await getDocs(collection(db, "pastoralisme"));

		data.forEach((item) => {
			setContent((content) => content.concat(item.data()));
		});
	};
	useEffect(() => {
		fetchContent();

		// eslint-disable-next-line
	}, []);

	const titre = content.find((c) => c.indice === "titre");

	const pastoralisme = content.find((c) => c.indice === "pastoralisme");
	const monde = content.find((c) => c.indice === "monde");
	const menaces = content.find((c) => c.indice === "menaces");
	const menaces_part2 = content.find((c) => c.indice === "menaces_part2");

	const loups = content.find((c) => c.indice === "loups");
	const qui = content.find((c) => c.indice === "qui");
	const qui_part2 = content.find((c) => c.indice === "qui_part2");
	const population = content.find((c) => c.indice === "population");

	const impact = content.find((c) => c.indice === "impact");
	const part = content.find((c) => c.indice === "part");
	const dommage = content.find((c) => c.indice === "dommage");
	const risque = content.find((c) => c.indice === "risque");

	const dossier = content.find((c) => c.indice === "dossier");
	const status = content.find((c) => c.indice === "status");
	const plan = content.find((c) => c.indice === "plan");
	const plan_part2 = content.find((c) => c.indice === "plan_part2");

	const mesures = content.find((c) => c.indice === "mesures");
	const indemnisation = content.find((c) => c.indice === "status");
	const predation = content.find((c) => c.indice === "predation");
	const predation_part2 = content.find((c) => c.indice === "predation_part2");
	const protocole = content.find((c) => c.indice === "protocole");
	const urgence = content.find((c) => c.indice === "urgence");

	const protection = content.find((c) => c.indice === "protection");
	const triptyque = content.find((c) => c.indice === "triptyque");
	const ajd = content.find((c) => c.indice === "ajd");
	const moyens = content.find((c) => c.indice === "moyens");
	const moyens_part2 = content.find((c) => c.indice === "moyens_part2");
	const zoom = content.find((c) => c.indice === "zoom");
	const zoom_part2 = content.find((c) => c.indice === "zoom_part2");

	const regard = content.find((c) => c.indice === "regard");
	const effets = content.find((c) => c.indice === "effets");
	const evaluation = content.find((c) => c.indice === "ajd");
	const limites = content.find((c) => c.indice === "limites");

	return (
		<>
			{content.length > 0 ? (
				<>
					<Container className={classes.root}>
						<Grid container spacing={6}>
							<HeaderPage element={titre} />
							<SectionHeader element={pastoralisme} />
							<SectionImageRight element={monde} />
							<SectionImageLeft element={menaces} />
							<SectionImageTop element={menaces_part2} />
						</Grid>
						<Divider className={classes.root} />
						<Grid container spacing={6}>
							<SectionHeader element={loups} />
							<SectionImageRight element={qui} />
							<SectionImageTop element={qui_part2} />
							<SectionImageLeft element={population} />
						</Grid>
						<Divider className={classes.root} />
						<Grid container spacing={6}>
							<SectionHeader element={impact} />
							<SectionImageRight element={part} />
							<SectionImageLeft element={dommage} />
							<SectionImageRight element={risque} />
						</Grid>
						<Divider className={classes.root} />
						<Grid container spacing={6}>
							<SectionHeader element={dossier} />
							<SectionImageRight element={status} />
							<SectionImageLeft element={plan} />
							<SectionImageTop element={plan_part2} />
						</Grid>
						<Divider className={classes.root} />
						<Grid container spacing={6}>
							<SectionHeader element={mesures} />
							<SectionImageRight element={indemnisation} />
							<SectionImageLeft element={predation} />
							<SectionImageTop element={predation_part2} />
							<SectionImageRight element={protocole} />
							<SectionImageLeft element={urgence} />
						</Grid>
						<Divider className={classes.root} />
						<Grid container spacing={6}>
							<SectionHeader element={protection} />
							<SectionImageRight element={triptyque} />
							<SectionImageLeft element={ajd} />
							<SectionImageRight element={moyens} />
							<SectionImageTop element={moyens_part2} />
							<SectionImageLeft element={zoom} />
							<SectionImageTop element={zoom_part2} />
						</Grid>
						<Divider className={classes.root} />
						<Grid container spacing={6}>
							<SectionHeader element={regard} />
							<SectionImageRight element={effets} />
							<SectionImageLeft element={evaluation} />
							<SectionImageRight element={limites} />
						</Grid>
					</Container>
				</>
			) : (
				<Container className={classes.root}>
					<Grid container spacing={6}>
						<Grid item xs align="center">
							<Typography variant="h5">Chargement...</Typography>
						</Grid>
					</Grid>
				</Container>
			)}
		</>
	);
}

export default PastoralismePage;
