import { Container, Grid, makeStyles, Typography } from "@material-ui/core";import { collection, getDocs } from "firebase/firestore";

import React, { useEffect, useState } from "react";
import HeaderPage from "../../components/Page/HeaderPage";
import SectionImageLeft from "../../components/Page/SectionImageLeft";
import SectionImageRight from "../../components/Page/SectionImageRight";
import { db } from "../../services/firebaseDatabase";

const SCHEMA =
	process.env.PUBLIC_URL +
	"/img/projects/proxyloup/schma-de-fonctionnement-proxyloup.jpg";

const useStyle = makeStyles((theme) => ({
	root: { marginBottom: theme.spacing(6), marginTop: theme.spacing(6) },
}));
/**
 * Main component for the formation page
 * @returns
 */
function ProxyloupPage() {
	const classes = useStyle();
	const [content, setContent] = useState([]);
	const fetchContent = async () => {
		const data = await getDocs(collection(db, "Proxyloup"));

		data.forEach((item) => {
			setContent((content) => content.concat(item.data()));
		});
	};
	useEffect(() => {
		fetchContent();

		// eslint-disable-next-line
	}, []);

	const proxyloup = content.find((c) => c.indice === "proxyloup");
	const presentation = content.find((c) => c.indice === "presentation");
	const application = content.find((c) => c.indice === "application");

	return (
		<>
			{content.length > 0 ? (
				<Container className={classes.root}>
					<Grid container spacing={8}>
						<HeaderPage element={proxyloup} />
						<SectionImageRight element={presentation} />
						<Grid item xs={12}>
							<img
								src={SCHEMA}
								alt={"schema"}
								width="100%"
								draggable={false}
							/>
						</Grid>
						<SectionImageLeft element={application} />
					</Grid>
				</Container>
			) : (
				<Container className={classes.root}>
					<Grid container spacing={6}>
						<Grid item xs align="center">
							<Typography variant="h5">Chargement...</Typography>
						</Grid>
					</Grid>
				</Container>
			)}
		</>
	);
}

export default ProxyloupPage;
