import { RichTextInput } from "ra-input-rich-text";import React from "react";
import {
	ChipField,
	Create,
	Datagrid,
	Edit,
	ImageField,
	ImageInput,
	List,
	ReferenceArrayField,
	ReferenceArrayInput,
	SelectArrayInput,
	SimpleForm,
	SingleFieldList,
	TextField,
	TextInput,
} from "react-admin";
import { PostPanel } from "../components/PostPanel";

export function PageList(props) {
	return (
		<List {...props} sort={{ field: "order", order: "ASC" }}>
			<Datagrid rowClick="edit" expand={<PostPanel />}>
				<TextField source="order" label="Position dans la page" />
				<TextField source="title" label="Titre" />

				<TextField source="legend" label="Légende de l'image" />
				<ReferenceArrayField
					label="Documents/Références"
					reference="documents"
					source="documents_id"
				>
					<SingleFieldList>
						<ChipField source="title" />
					</SingleFieldList>
				</ReferenceArrayField>
			</Datagrid>
		</List>
	);
}

export const PageCreate = (props) => {
	return (
		<Create title="Nouveau" {...props}>
			<SimpleForm>
				<TextInput
					source="order"
					label="Position dans la page"
					resettable
					fullWidth
				/>

				{/* <TextInput source="indice" label="Indice" resettable /> */}

				<TextInput source="title" label="Titre" resettable fullWidth />
				<RichTextInput source="body" label="Contenu" />
				<ImageInput source="pictures" label="Image" accept="image/*">
					<ImageField source="src" title="title" />
				</ImageInput>
				<TextInput
					source="legend"
					label="Légende"
					resettable
					fullWidth
				/>
				<ReferenceArrayInput
					label="Documents/Références"
					source="documents_id"
					reference="documents"
					sort={{ field: "published", order: "DESC" }}
					allowEmpty
					fullWidth
				>
					<SelectArrayInput optionText="title" />
				</ReferenceArrayInput>
			</SimpleForm>
		</Create>
	);
};

export const PageEdit = (props) => {
	return (
		<Edit title="Modifier" {...props} undoable={false}>
			<SimpleForm>
				<TextInput
					source="order"
					label="Position dans la page"
					resettable
					fullWidth
				/>

				{/* <TextInput source="indice" label="Indice" disabled /> */}

				<TextInput source="title" label="Titre" fullWidth />
				<RichTextInput source="body" label="Contenu" />
				<ImageInput
					source="pictures"
					label="Related pictures"
					accept="image/*"
				>
					<ImageField source="src" title="title" />
				</ImageInput>
				<TextInput
					source="legend"
					label="Légende"
					resettable
					fullWidth
				/>
				<ReferenceArrayInput
					label="Documents/Références"
					source="documents_id"
					reference="documents"
					sort={{ field: "published", order: "DESC" }}
					allowEmpty
					fullWidth
				>
					<SelectArrayInput optionText="title" />
				</ReferenceArrayInput>
			</SimpleForm>
		</Edit>
	);
};
