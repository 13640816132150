function calculateDate(published) {
	const month_name = [
		"Janvier",
		"Février",
		"Mars",
		"Avril",
		"Mai",
		"Juin",
		"Juillet",
		"Août",
		"Septembre",
		"Octobre",
		"Novembre",
		"Décembre",
	];
	var date = new Date(published);
	var date_month = month_name[date.getMonth()];
	return date.getDate() + " " + date_month + " " + date.getFullYear();
}

export default calculateDate;
