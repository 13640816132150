import { CssBaseline } from "@material-ui/core";import { ThemeProvider } from "@material-ui/styles";
import React from "react";
import FABScrollToTop from "./components/FABScrollToTop";
import NavRoutes from "./routes";
import theme from "./theme";

function App() {
	return (
		<ThemeProvider theme={theme}>
			<CssBaseline />
			<NavRoutes />
			<FABScrollToTop />
		</ThemeProvider>
	);
}

export default App;
