import { Grid, IconButton, makeStyles, Typography } from "@material-ui/core";import { CloudDownloadRounded } from "@material-ui/icons";
import React from "react";
import { Link } from "react-router-dom";
import LightTooltip from "./LightToolTips";

const useStyle = makeStyles((theme) => ({
	icon: { fontSize: theme.spacing(8), color: theme.palette.primary.main },
	text: { textDecoration: "none", color: theme.palette.primary.main },
}));

function DownloadFile({ title, subtitle, file }) {
	const classes = useStyle();
	return (
		<Grid item container alignItems="center" direction="row">
			<Grid item xs={3} align="right">
				<LightTooltip title="Télécharger" enterDelay={1000}>
					<IconButton>
						<Link to={file} target="_blank" download>
							<CloudDownloadRounded className={classes.icon} />
						</Link>
					</IconButton>
				</LightTooltip>
			</Grid>
			<Grid
				item
				container
				xs={9}
				alignItems="flex-start"
				justifyContent="center"
				direction="column"
			>
				<Link
					to={file}
					target="_blank"
					download
					className={classes.text}
				>
					<Typography>{title}</Typography>
				</Link>
				<Typography>{subtitle}</Typography>
			</Grid>
		</Grid>
	);
}

export default DownloadFile;
