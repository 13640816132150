import { RichTextInput } from "ra-input-rich-text";import React from "react";
import {
	Create,
	Datagrid,
	Edit,
	List,
	RichTextField,
	SimpleForm,
	TextField,
	TextInput,
} from "react-admin";

export function ContactList(props) {
	return (
		<List {...props} perPage={25}>
			<Datagrid rowClick="edit">
				{/* <TextField source="indice" label="Indice" /> */}
				<TextField source="title" label="Titre" />
				<RichTextField source="body" label="Contenu" />
			</Datagrid>
		</List>
	);
}

export const ContactCreate = (props) => {
	return (
		<Create title="Nouveau" {...props}>
			<SimpleForm>
				{/* <TextInput source="indice" label="Indice" resettable /> */}
				<TextInput source="title" label="Titre" resettable />
				<RichTextInput source="body" label="Contenu" />
			</SimpleForm>
		</Create>
	);
};

export const ContactEdit = (props) => {
	return (
		<Edit title="Modifier" {...props} undoable={false}>
			<SimpleForm>
				{/* <TextInput source="indice" label="Indice" resettable /> */}
				<TextInput source="title" label="Titre" resettable />
				<RichTextInput source="body" label="Contenu" />
			</SimpleForm>
		</Edit>
	);
};
