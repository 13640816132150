import { RichTextInput } from "ra-input-rich-text";import React from "react";
import {
	BooleanField,
	BooleanInput,
	ChipField,
	Create,
	Datagrid,
	DateField,
	DateInput,
	Edit,
	ImageField,
	ImageInput,
	List,
	ReferenceArrayField,
	ReferenceArrayInput,
	SelectArrayInput,
	SimpleForm,
	SingleFieldList,
	TextField,
	TextInput,
} from "react-admin";
import { PostPanel } from "../components/PostPanel";

export const NewsList = (props) => (
	<List {...props} sort={{ field: "published", order: "DESC" }} perPage={25}>
		<Datagrid rowClick="edit" expand={<PostPanel />}>
			<BooleanField source="isPublished" label="Publié" />

			<TextField source="title" label="Titre" />
			<DateField source="published" label="Date de Publication" />
			<ReferenceArrayField
				label="Documents/Références"
				reference="documents"
				source="documents_id"
			>
				<SingleFieldList>
					<ChipField source="title" />
				</SingleFieldList>
			</ReferenceArrayField>
		</Datagrid>
	</List>
);

export const NewsCreate = (props) => {
	return (
		<Create title="Nouvelle Actualité" {...props}>
			<SimpleForm>
				<BooleanInput
					label="Publié"
					source="isPublished"
					initialValue={true}
				/>
				<TextInput source="title" label="Titre" resettable fullWidth />
				<TextInput
					source="teaser"
					label="Aperçu du contenu"
					resettable
					multiline
					fullWidth
				/>
				<RichTextInput source="body" label="Contenu" />
				<DateInput
					source="published"
					label="Date de publication"
					fullWidth
				/>
				<ImageInput
					source="pictures"
					label="Image de présentation"
					accept="image/*"
				>
					<ImageField source="src" title="title" />
				</ImageInput>
				<ReferenceArrayInput
					label="Documents/Références"
					source="documents_id"
					reference="documents"
					perPage={100}
					sort={{ field: "category", order: "DESC" }}
					allowEmpty
					fullWidth
				>
					<SelectArrayInput optionText="title" />
				</ReferenceArrayInput>
			</SimpleForm>
		</Create>
	);
};
export const NewsEdit = (props) => {
	return (
		<Edit title="Modifier l'Actualité" {...props} undoable={false}>
			<SimpleForm>
				<BooleanInput
					label="Publié"
					source="isPublished"
					initialValue={true}
				/>
				<TextInput source="title" label="Titre" resettable fullWidth />
				<TextInput
					source="teaser"
					label="Aperçu du contenu"
					resettable
					multiline
					fullWidth
				/>
				<RichTextInput source="body" label="Contenu" />
				<DateInput
					source="published"
					label="Date de publication"
					fullWidth
				/>
				<ImageInput
					source="pictures"
					label="Related pictures"
					accept="image/*"
				>
					<ImageField source="src" title="title" />
				</ImageInput>
				<ReferenceArrayInput
					label="Documents/Références"
					source="documents_id"
					reference="documents"
					perPage={100}
					sort={{ field: "published", order: "DESC" }}
					allowEmpty
					fullWidth
				>
					<SelectArrayInput optionText="title" />
				</ReferenceArrayInput>
			</SimpleForm>
		</Edit>
	);
};
