import { RichTextInput } from "ra-input-rich-text";import React from "react";
import {
	AutocompleteInput,
	BooleanField,
	BooleanInput,
	Create,
	Datagrid,
	DateField,
	DateInput,
	Edit,
	Filter,
	ImageField,
	ImageInput,
	List,
	required,
	SelectField,
	SimpleForm,
	TextField,
	TextInput,
} from "react-admin";
import { PostPanelVideo } from "../components/PostPanel";

const DocFilter = (props) => (
	<Filter {...props}>
		<BooleanInput label="Publié" source="isPublished" initialValue={true} />
		<TextInput label="Titre" source="title" />
		<AutocompleteInput
			source="category"
			label="Type de Document"
			choices={[
				{ id: "document_ipra", name: "Document IPRA" },
				{ id: "presse", name: "Presse" },
				{ id: "documentaire", name: "Documentaire" },
				{ id: "cdp_news", name: "Revue CDP News" },
				{ id: "bibliographie", name: "Bibliographie" },
			]}
		/>
	</Filter>
);

export const DocumentsList = (props) => (
	<List
		{...props}
		sort={{ field: "published", order: "DESC" }}
		filters={<DocFilter />}
		perPage={25}
	>
		<Datagrid rowClick="edit" expand={<PostPanelVideo />}>
			<BooleanField source="isPublished" label="Publié" />
			<SelectField
				source="category"
				label="Type de Document"
				choices={[
					{ id: "document_ipra", name: "Document IPRA" },
					{ id: "presse", name: "Presse" },
					{ id: "documentaire", name: "Documentaire" },
					{ id: "cdp_news", name: "Revue CDP News" },
					{ id: "bibliographie", name: "Bibliographie" },
				]}
			/>
			<TextField source="title" label="Titre" />
			<DateField source="published" label="Date de Publication" />
		</Datagrid>
	</List>
);

export const DocumentsCreate = (props) => {
	return (
		<Create title="Nouveau Document" {...props}>
			<SimpleForm>
				<BooleanInput
					label="Publié"
					source="isPublished"
					initialValue={true}
				/>
				<AutocompleteInput
					source="category"
					label="Type de Document"
					choices={[
						{ id: "document_ipra", name: "Document IPRA" },
						{ id: "presse", name: "Presse" },
						{ id: "documentaire", name: "Documentaire" },
						{ id: "cdp_news", name: "Revue CDP News" },
						{ id: "bibliographie", name: "Bibliographie" },
					]}
					validate={[required()]}
					fullWidth
				/>
				<TextInput
					source="title"
					label="Titre"
					resettable
					validate={[required()]}
					fullWidth
				/>

				<ImageInput
					source="pictures"
					label="Image de l'aperçu"
					accept="image/*"
				>
					<ImageField source="src" title="title" />
				</ImageInput>
				<RichTextInput source="description" label="Description" />
				<DateInput
					source="published"
					label="Date de publication"
					fullWidth
				/>
				<TextInput
					source="id_gdrive"
					label="ID du document GDrive"
					resettable
					fullWidth
				/>
			</SimpleForm>
		</Create>
	);
};

export const DocumentsEdit = (props) => {
	return (
		<Edit title="Modifier le document" {...props} undoable={false}>
			<SimpleForm>
				<BooleanInput
					label="Publié"
					source="isPublished"
					initialValue={true}
				/>

				<AutocompleteInput
					source="category"
					label="Type de Document"
					choices={[
						{ id: "document_ipra", name: "Document IPRA" },
						{ id: "presse", name: "Presse" },
						{ id: "documentaire", name: "Documentaire" },
						{ id: "cdp_news", name: "Revue CDP News" },
						{ id: "bibliographie", name: "Bibliographie" },
					]}
					validate={[required()]}
					fullWidth
				/>
				<TextInput
					source="title"
					label="Titre"
					resettable
					validate={[required()]}
					fullWidth
				/>

				<ImageInput
					source="pictures"
					label="Image de l'aperçu"
					accept="image/*"
				>
					<ImageField source="src" title="title" />
				</ImageInput>
				<RichTextInput source="description" label="Description" />
				<DateInput
					source="published"
					label="Date de publication"
					fullWidth
				/>
				<TextInput
					source="id_gdrive"
					label="Id du document GDrive"
					resettable
					fullWidth
				/>
			</SimpleForm>
		</Edit>
	);
};
