import {	Container,
	Grid,
	IconButton,
	makeStyles,
	Typography,
} from "@material-ui/core";
import { ArrowBackRounded } from "@material-ui/icons";
import { collection, getDocs } from "firebase/firestore";

import React, { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import LightTooltip from "../../../components/LightToolTips";
import ReferencesSection from "../../../components/Page/ReferencesSection";
import { db } from "../../../services/firebaseDatabase";

const useStyle = makeStyles((theme) => ({
	root: { marginBottom: theme.spacing(6), marginTop: theme.spacing(6) },
	icon: { fontSize: theme.spacing(8), color: theme.palette.primary.main },
	date: { fontStyle: "italic", color: theme.palette.primary.main },

	title: {
		fontSize: "4rem",
		fontWeight: "100",
		[theme.breakpoints.down("sm")]: {
			fontSize: "3rem",
		},
	},
	body: { width: "100%" },
}));

function ShowVideo() {
	const classes = useStyle();

	const { id } = useParams();
	const [document, setDoc] = useState();
	const fetchDocuments = async () => {
		const data = await getDocs(collection(db, "videotheque"));

		data.forEach((item) => {
			if (item.id === id) setDoc(item.data());
		});
	};
	useEffect(() => {
		fetchDocuments();
		// eslint-disable-next-line
	}, []);

	return (
		<Container className={classes.root}>
			{document ? (
				<>
					<Grid container direction="row" alignItems="center">
						<Grid item md={1}>
							<LightTooltip
								title="Retour à la vidéothèque"
								enterDelay={1000}
							>
								<IconButton>
									<Link to={"/videotheque"}>
										<ArrowBackRounded
											className={classes.icon}
										/>
									</Link>
								</IconButton>
							</LightTooltip>
						</Grid>
						<Grid item md={11}>
							<Typography className={classes.title}>
								{document.title}
							</Typography>
							<Typography variant="h6" className={classes.date}>
								Séquence #{document.number}
							</Typography>
						</Grid>
					</Grid>
					<Grid container spacing={8} className={classes.root}>
						<Grid
							item
							container
							xs
							justifyContent="center"
							direction="column"
						>
							<Typography
								className={classes.body}
								align="justify"
								dangerouslySetInnerHTML={{
									__html: document.description,
								}}
							></Typography>
							<ReferencesSection links={document.documents_id} />
						</Grid>
					</Grid>
				</>
			) : (
				<Grid container spacing={6}>
					<Grid item xs align="center">
						<Typography variant="h5">Chargement...</Typography>
					</Grid>
				</Grid>
			)}
		</Container>
	);
}

export default ShowVideo;
