import {	Container,
	Divider,
	Grid,
	makeStyles,
	Typography,
} from "@material-ui/core";
import { collection, getDocs } from "firebase/firestore";

import React, { useEffect, useState } from "react";
import HeaderPage from "../../components/Page/HeaderPage";
import SectionHeader from "../../components/Page/SectionHeader";
import SectionImageLeft from "../../components/Page/SectionImageLeft";
import SectionImageRight from "../../components/Page/SectionImageRight";
import { db } from "../../services/firebaseDatabase";
const useStyle = makeStyles((theme) => ({
	root: { marginBottom: theme.spacing(6), marginTop: theme.spacing(6) },
}));
/**
 * Main component for the formation page
 * @returns
 */
function PerformancePage() {
	const classes = useStyle();
	const [content, setContent] = useState([]);
	const fetchContent = async () => {
		const data = await getDocs(collection(db, "prestations"));

		data.forEach((item) => {
			setContent((content) => content.concat(item.data()));
		});
	};
	useEffect(() => {
		fetchContent();

		// eslint-disable-next-line
	}, []);

	const prestation = content.find((c) => c.indice === "prestation");
	const accompagnement = content.find((c) => c.indice === "accompagnement");
	const expertise = content.find((c) => c.indice === "expertise");
	const cases = content.find((c) => c.indice === "cases");
	const formation = content.find((c) => c.indice === "formation");
	const dog = content.find((c) => c.indice === "dog");
	const wolf = content.find((c) => c.indice === "wolf");
	const ethologie = content.find((c) => c.indice === "ethologie");
	const conference = content.find((c) => c.indice === "conference");

	return (
		<>
			{content.length > 0 ? (
				<>
					<Container className={classes.root}>
						<HeaderPage element={prestation} />
						<Divider className={classes.root} />
						<Grid container spacing={8}>
							<SectionHeader element={accompagnement} />
							<SectionImageRight element={expertise} />
							<SectionImageLeft element={cases} />
						</Grid>
						<Divider className={classes.root} />
						<Grid container spacing={8}>
							<SectionHeader element={formation} />
							<SectionImageRight element={dog} />
							<SectionImageLeft element={wolf} />
							<SectionImageRight element={ethologie} />
						</Grid>
						<Divider className={classes.root} />
						<Grid container spacing={8}>
							<SectionHeader element={conference} />
						</Grid>
					</Container>
				</>
			) : (
				<Container className={classes.root}>
					<Grid container spacing={6}>
						<Grid item xs align="center">
							<Typography variant="h5">Chargement...</Typography>
						</Grid>
					</Grid>
				</Container>
			)}
		</>
	);
}

export default PerformancePage;
