import {	Container,
	Divider,
	Grid,
	makeStyles,
	Typography,
} from "@material-ui/core";
import { collection, getDocs } from "firebase/firestore";

import React, { useEffect, useState } from "react";
import HeaderPage from "../../components/Page/HeaderPage";
import SectionHeader from "../../components/Page/SectionHeader";
import { db } from "../../services/firebaseDatabase";
import ContactSection from "./components/ContactSection";
const useStyle = makeStyles((theme) => ({
	root: { marginBottom: theme.spacing(12), marginTop: theme.spacing(6) },
	divider: { marginBottom: theme.spacing(6), marginTop: theme.spacing(6) },
}));

/**
 * Main component for the formation page
 * @returns
 */
function ContactPage() {
	const classes = useStyle();
	const [content, setContent] = useState([]);
	const fetchContent = async () => {
		const data = await getDocs(collection(db, "contact"));

		data.forEach((item) => {
			setContent((content) => content.concat(item.data()));
		});
	};
	useEffect(() => {
		fetchContent();

		// eslint-disable-next-line
	}, []);

	const info_ipra = content.find((c) => c.indice === "info_ipra");
	const tel_ipra = content.find((c) => c.indice === "tel_ipra");
	const suisse_ipra = content.find((c) => c.indice === "suisse_ipra");
	const france_ipra = content.find((c) => c.indice === "france_ipra");
	const info_fjml = content.find((c) => c.indice === "info_fjml");
	const tel_fjml = content.find((c) => c.indice === "tel_fjml");
	const adresse_fjml = content.find((c) => c.indice === "adresse_fjml");

	return (
		<>
			{content.length > 0 ? (
				<Container className={classes.root}>
					<HeaderPage element={{ title: "Coordonnées" }} />
					<Divider className={classes.divider} />

					<Grid container spacing={8} justifyContent="center">
						<SectionHeader element={{ title: "Contacts IPRA" }} />
						{info_ipra && (
							<ContactSection
								title={info_ipra.title}
								body={info_ipra.body}
							/>
						)}
						{tel_ipra && (
							<ContactSection
								title={tel_ipra.title}
								body={tel_ipra.body}
							/>
						)}
						{suisse_ipra && (
							<ContactSection
								title={suisse_ipra.title}
								body={suisse_ipra.body}
							/>
						)}
						{france_ipra && (
							<ContactSection
								title={france_ipra.title}
								body={france_ipra.body}
							/>
						)}
					</Grid>
					<Divider className={classes.divider} />
					<Grid container spacing={8}>
						<SectionHeader element={{ title: "Contacts FJML" }} />

						{info_fjml && (
							<ContactSection
								title={info_fjml.title}
								body={info_fjml.body}
							/>
						)}
						{tel_fjml && (
							<ContactSection
								title={tel_fjml.title}
								body={tel_fjml.body}
							/>
						)}
						{adresse_fjml && (
							<ContactSection
								title={adresse_fjml.title}
								body={adresse_fjml.body}
							/>
						)}
					</Grid>
				</Container>
			) : (
				<Container className={classes.root}>
					<Grid container spacing={6}>
						<Grid item xs align="center">
							<Typography variant="h5">Chargement...</Typography>
						</Grid>
					</Grid>
				</Container>
			)}
		</>
	);
}

export default ContactPage;
