import { Grid, makeStyles, Typography } from "@material-ui/core";import React from "react";
import Image from "../Image";
import ReferencesSection from "./ReferencesSection";

const useStyle = makeStyles((theme) => ({
	root: { marginBottom: theme.spacing(6), marginTop: theme.spacing(6) },
	legend: {
		marginTop: theme.spacing(1),
		color: theme.palette.primary.main,
		fontStyle: "italic",
		textAlign: "center",
	},
	body: {
		fontSize: "1.3em",
		textAlign: "right",
		width: "100%",
	},
}));

function SectionImageRight({ element }) {
	const classes = useStyle();
	return (
		<>
			{element && (
				<Grid item container spacing={6} id={element.title || ""}>
					{element.title ? (
						<Grid
							item
							container
							md={6}
							xs={12}
							alignItems="flex-end"
							direction="column"
							justifyContent="center"
						>
							<Typography variant="h4">
								{element.title}
							</Typography>
							{element.body && (
								<Typography
									align="justify"
									dangerouslySetInnerHTML={{
										__html: element.body,
									}}
								></Typography>
							)}
							<ReferencesSection links={element.documents_id} />
						</Grid>
					) : (
						<Grid
							item
							container
							md={6}
							xs={12}
							justifyContent="flex-end"
							alignItems="center"
						>
							{element.body && (
								<Typography
									align="justify"
									dangerouslySetInnerHTML={{
										__html: element.body,
									}}
								></Typography>
							)}
							<ReferencesSection links={element.documents_id} />
						</Grid>
					)}
					<Grid
						item
						container
						md={6}
						xs={12}
						justifyContent="center"
						direction="column"
					>
						{element.pictures && (
							<Image
								src={element.pictures.src}
								alt={element.pictures.title}
								width="100%"
							/>
						)}
						{element.legend && (
							<Typography className={classes.legend}>
								{element.legend}
							</Typography>
						)}
					</Grid>
				</Grid>
			)}
		</>
	);
}

export default SectionImageRight;
