import { Container, Grid, makeStyles, Typography } from "@material-ui/core";
import { collection, getDocs } from "firebase/firestore";

import React, { useEffect, useState } from "react";
import Image from "../../components/Image";
import HeaderPage from "../../components/Page/HeaderPage";
import SectionHeader from "../../components/Page/SectionHeader";
import SectionImageLeft from "../../components/Page/SectionImageLeft";
import SectionImageRight from "../../components/Page/SectionImageRight";
import SectionImageTop from "../../components/Page/SectionImageTop";
import { db } from "../../services/firebaseDatabase";
const MERCANTOUR =
	process.env.PUBLIC_URL + "/img/projects/canovis/territoire1.jpg";

const CANJUERS =
	process.env.PUBLIC_URL + "/img/projects/canovis/territoire3.jpg";

const MERCANTOUR2 =
	process.env.PUBLIC_URL + "/img/projects/canovis/territoire2.jpg";

const CANJUERS2 =
	process.env.PUBLIC_URL + "/img/projects/canovis/territoire4.jpg";

const BUECH = process.env.PUBLIC_URL + "/img/projects/canovis/battant-2021.jpg";

const SYNTHESE =
	process.env.PUBLIC_URL +
	"img/projects/canovis/synthse-vulnrabilit-2021.jpg";
const LOUP =
	process.env.PUBLIC_URL +
	"img/projects/canovis/vlcsnap-2018-06-10-09h28m35s167.jpg";

const useStyle = makeStyles((theme) => ({
	root: { marginBottom: theme.spacing(6), marginTop: theme.spacing(6) },
	legend: {
		marginTop: theme.spacing(1),
		color: theme.palette.primary.main,
		fontStyle: "italic",
		textAlign: "center",
	},
	middle: {
		textAlign: "center",
		fontSize: "1.3em",
		backgroundColor: theme.palette.primary.main,
		color: theme.palette.secondary.light,
		padding: theme.spacing(6),
	},
}));
/**
 * Main component for the formation page
 * @returns
 */
function CanovisPage() {
	const classes = useStyle();
	const [content, setContent] = useState([]);
	const fetchContent = async () => {
		const data = await getDocs(collection(db, "Canovis"));

		data.forEach((item) => {
			setContent((content) => content.concat(item.data()));
		});
	};
	useEffect(() => {
		fetchContent();

		// eslint-disable-next-line
	}, []);

	const canovis = content.find((c) => c.indice === "canovis");
	const territoire = content.find((c) => c.indice === "territoire");
	const resume = content.find((c) => c.indice === "resume");
	const resume2 = content.find((c) => c.indice === "resume2");
	const final = content.find((c) => c.indice === "final");
	const final2 = content.find((c) => c.indice === "final2");
	const rapport = content.find((c) => c.indice === "rapport");

	return (
		<>
			{content.length > 0 ? (
				<>
					<Container className={classes.root}>
						<Grid container spacing={8}>
							<HeaderPage element={canovis} />
							<SectionImageLeft element={territoire} />
							<Grid item xs={12} md={6}>
								<Image
									src={MERCANTOUR}
									alt={"mercantour"}
									width="100%"
								/>
								<Typography className={classes.legend}>
									Estive d’altitude dans le Mercantour
								</Typography>
							</Grid>
							<Grid item xs={12} md={6}>
								<Image
									src={CANJUERS}
									alt={"canjuers"}
									width="100%"
								/>
								<Typography className={classes.legend}>
									Zone de pâture sur le site d’étude de
									Canjuers
								</Typography>
							</Grid>
							<Grid item xs={12} md={6}>
								<Image
									src={MERCANTOUR2}
									alt={"mercantour"}
									width="100%"
								/>
								<Typography className={classes.legend}>
									Estive avec partie boisée dans le Mercantour
								</Typography>
							</Grid>
							<Grid item xs={12} md={6}>
								<Image
									src={CANJUERS2}
									alt={"canjuers"}
									width="100%"
								/>
								<Typography className={classes.legend}>
									Troupeau pâturant sur le site d’étude de
									Canjuers
								</Typography>
							</Grid>
							<Grid item xs={12}>
								<Image src={BUECH} alt={"buech"} width="100%" />
								<Typography className={classes.legend}>
									Alpages du Haut Buech
								</Typography>
							</Grid>
							<SectionHeader element={resume} />
							<SectionImageRight element={resume2} />
							<SectionHeader element={final} />
							<Grid item xs={12}>
								<Typography className={classes.middle}>
									<>
										<b>
											Suivi nocturne 2013-2018 en quelques
											chiffres :
										</b>
										<br />
										<br /> 3300 h de monitoring
										<br />
										<br /> 530 séquences impliquant des
										loups dont 163 approches de troupeaux ou
										lots de bétail <br />
										<br />
										175 interactions observées entre des
										loups et des chiens de protection
									</>
								</Typography>
							</Grid>
							<SectionImageRight element={final2} />
							<SectionImageTop element={rapport} />
							<Grid item xs={12} md={6}>
								<Image
									src={SYNTHESE}
									alt={"synthese"}
									width="100%"
								/>
								<Typography className={classes.legend}>
									Figure de synthèse sur la vulnérabilité
									effective d'un troupeau (voir rapport final)
								</Typography>
							</Grid>
							<Grid item xs={12} md={6}>
								<Image src={LOUP} alt={"loup"} width="100%" />
								<Typography className={classes.legend}>
									Un loup à l'approche du troupeau
								</Typography>
							</Grid>
						</Grid>
					</Container>
				</>
			) : (
				<Container className={classes.root}>
					<Grid container spacing={6}>
						<Grid item xs align="center">
							<Typography variant="h5">Chargement...</Typography>
						</Grid>
					</Grid>
				</Container>
			)}
		</>
	);
}

export default CanovisPage;
