import Typography from "@material-ui/core/Typography";import { makeStyles } from "@material-ui/core/styles";
import SettingsIcon from "@material-ui/icons/Settings";
import compatApp from "firebase/compat/app";
import "firebase/compat/auth";
import "firebase/compat/firestore";
import React, { forwardRef } from "react";
import { AppBar, MenuItemLink, UserMenu } from "react-admin";
import { firebaseConfig } from "../../../services/firebaseConfig";
const useStyles = makeStyles((theme) => ({
	title: {
		flex: 1,
		textOverflow: "ellipsis",
		whiteSpace: "nowrap",
		overflow: "hidden",
	},
	spacer: {
		flex: 1,
	},
	bar: {
		height: "64px",
		backgroundColor: theme.palette.primary.main,
		color: theme.palette.secondary.light,
		paddingTop: theme.spacing(1),
	},
	avatar: {
		height: 30,
		width: 30,
	},
}));

const ConfigurationMenu = forwardRef(({ onClick }, ref) => (
	<MenuItemLink
		ref={ref}
		to="/configuration"
		primaryText="Configuration"
		leftIcon={<SettingsIcon />}
		onClick={onClick} // close the menu on click
	/>
));

const MyUserMenu = (props) => {
	compatApp.initializeApp(firebaseConfig);
	var user = compatApp.auth().currentUser;

	return (
		<UserMenu {...props}>
			{user && user.email !== "presse@ipra-fjml.com" && (
				<ConfigurationMenu />
			)}
		</UserMenu>
	);
};

const MyAppBar = (props) => {
	const classes = useStyles();

	return (
		<AppBar {...props} className={classes.bar} userMenu={<MyUserMenu />}>
			<Typography
				variant="h6"
				color="inherit"
				className={classes.title}
				id="react-admin-title"
			/>
			<Typography variant="h4">Administration</Typography>
			<span className={classes.spacer} />
		</AppBar>
	);
};

export default MyAppBar;
