import React from "react";import HomepageHeader from "./components/HomepageHeader";
import HomepageProjects from "./components/HomepageProjects";
import HomepageYoutube from "./components/HomepageYoutube";
import HomepageNewsContainer from "./containers/HomepageNewsContainer";

/**
 * Main component for the homepage
 * @returns
 */
function HomePage() {
	return (
		<>
			<HomepageHeader />
			<HomepageNewsContainer />
			<HomepageProjects />
			<HomepageYoutube />
			{/* <HomepageJoining /> */}
		</>
	);
}

export default HomePage;
