import {	Container,
	Grid,
	IconButton,
	makeStyles,
	Menu,
	MenuItem,
	Typography,
} from "@material-ui/core";
import { LinkedIn } from "@material-ui/icons";
import FacebookIcon from "@material-ui/icons/Facebook";
import InstagramIcon from "@material-ui/icons/Instagram";
import TwitterIcon from "@material-ui/icons/Twitter";

import React, { useState } from "react";
import { Link } from "react-router-dom";
import LightTooltip from "../LightToolTips";

const useStyle = makeStyles((theme) => ({
	root: {
		width: "100%",
		backgroundColor: theme.palette.primary.main,
		color: theme.palette.secondary.light,
		paddingTop: theme.spacing(6),
		paddingBottom: theme.spacing(6),
	},
	footerItem: {
		[theme.breakpoints.down("sm")]: {
			marginBottom: theme.spacing(4),
		},
	},
	typoSubtitle: {
		fontWeight: "Bold",
		marginBottom: theme.spacing(2),
	},
	typo: {
		fontWeight: 200,
		fontSize: 16,
		whiteSpace: "nowrap",
		color: theme.palette.secondary.light,
		textDecoration: "none",
	},
	link: { margin: 0 },
	copyright: {
		[theme.breakpoints.up("md")]: {
			marginTop: theme.spacing(6),
		},
		fontSize: 16,
		textAlign: "center",
	},
}));

/**
 * Component for the footer on the website
 * @returns
 */
function Footer() {
	const classes = useStyle();
	const [anchorEl, setAnchorEl] = useState(null);

	const handleClick = (event) => {
		setAnchorEl(event.currentTarget);
	};

	const handleClose = () => {
		setAnchorEl(null);
	};

	return (
		<div className={classes.root}>
			<Container>
				<Grid container>
					<Grid
						className={classes.footerItem}
						item
						xs={12}
						md
						container
						direction="column"
						alignItems="center"
					>
						<Typography className={classes.typoSubtitle}>
							NOUS CONTACTER
						</Typography>

						<Link to="/contact" className={classes.typo}>
							Coordonnées
						</Link>
						<Link to="/joining" className={classes.typo}>
							Collaborer
						</Link>
						<Link to="/administration" className={classes.typo}>
							Administration
						</Link>
					</Grid>
					<Grid
						className={classes.footerItem}
						item
						xs={12}
						md
						container
						direction="column"
						alignItems="center"
					>
						<Typography className={classes.typoSubtitle}>
							A PROPOS
						</Typography>
						<Link to="/" className={classes.typo}>
							Accueil
						</Link>
						<Link to="/mentions" className={classes.typo}>
							Mentions légales
						</Link>
						<Link to="/team" className={classes.typo}>
							L'Equipe
						</Link>
					</Grid>
					<Grid
						className={classes.footerItem}
						item
						xs={12}
						md
						container
						direction="column"
						alignItems="center"
					>
						<Grid item>
							<Typography className={classes.typoSubtitle}>
								SUIVEZ NOUS
							</Typography>
						</Grid>

						<Grid
							item
							container
							justifyContent="center"
							alignItems="center"
							className={classes.link}
						>
							<LightTooltip title="Facebook" enterDelay={1000}>
								<IconButton
									color={"inherit"}
									onClick={handleClick}
								>
									<FacebookIcon />
								</IconButton>
							</LightTooltip>
							<LightTooltip title="Instagram" enterDelay={1000}>
								<IconButton
									color={"inherit"}
									onClick={(e) =>
										window.open(
											"https://www.instagram.com/ipra.landry/"
										)
									}
								>
									<InstagramIcon />
								</IconButton>
							</LightTooltip>

							<LightTooltip title="Twitter" enterDelay={1000}>
								<IconButton
									color={"inherit"}
									onClick={() =>
										window.open(
											"https://twitter.com/ipra_landry"
										)
									}
								>
									<TwitterIcon />
								</IconButton>
							</LightTooltip>

							<LightTooltip title="LinkedIn" enterDelay={1000}>
								<IconButton
									color={"inherit"}
									onClick={() =>
										window.open(
											"https://www.linkedin.com/company/fjml-fondation-jean-marc-landry"
										)
									}
								>
									<LinkedIn />
								</IconButton>
							</LightTooltip>

							{/* <LightTooltip title="Newsletter" enterDelay={1000}>
								<IconButton
									color={"inherit"}
									onClick={handleDialogClick}
								>
									<MailRounded />
								</IconButton>
							</LightTooltip> */}
						</Grid>

						<Menu
							id="simple-menu"
							anchorEl={anchorEl}
							keepMounted
							open={Boolean(anchorEl)}
							onClose={handleClose}
						>
							<MenuItem
								onClick={() => {
									handleClose();
									window.open(
										"https://www.facebook.com/ipra.landry"
									);
								}}
							>
								IPRA
							</MenuItem>
							<MenuItem
								onClick={() => {
									handleClose();
									window.open(
										"https://www.facebook.com/fondationjeanmarclandry"
									);
								}}
							>
								FJML
							</MenuItem>
						</Menu>
					</Grid>
					<Grid item container xs={12} justifyContent="center">
						<Typography className={classes.copyright}>
							© {new Date().getFullYear()} - IPRA-FJML - Tous
							droits réservés
						</Typography>
					</Grid>
				</Grid>
			</Container>
		</div>
	);
}

export default Footer;
