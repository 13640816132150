import { collection, getDocs } from "firebase/firestore";import React, { useEffect, useState } from "react";
import { db } from "../../../services/firebaseDatabase";
import VideosList from "./VideosList";
/**
 * Main component for the News page
 * @returns
 */
function VideoPage() {
	const [docs, setDocs] = useState([]);
	const fetchDocuments = async () => {
		const data = await getDocs(collection(db, "videotheque"));

		data.forEach((item) => {
			if (item.data().published === true) {
				setDocs((docs) =>
					docs.concat({ id: item.id, data: item.data() })
				);
			}
		});
	};
	useEffect(() => {
		fetchDocuments();
		// eslint-disable-next-line
	}, []);

	return <VideosList documents={docs} titre="Vidéothèque" />;
}

export default VideoPage;
