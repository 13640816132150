import {	Collapse,
	Divider,
	Drawer,
	List,
	ListItem,
	ListItemText,
	makeStyles,
} from "@material-ui/core";
import { ExpandLess, ExpandMore } from "@material-ui/icons";
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";

const useStyle = makeStyles((theme) => ({
	menu: {
		background: `linear-gradient(45deg, ${theme.palette.primary.main} 30%, ${theme.palette.primary.dark} 90%)`,
		color: theme.palette.secondary.main,
		paddingTop: theme.mixins.toolbar.minHeight,
	},
	nested: {
		marginRight: theme.spacing(3),
	},
}));

function MenuDrawer({ open, onClose }) {
	const classes = useStyle();
	const history = useNavigate();
	const [openSub, setOpen] = useState(false);

	const handleClick = () => {
		setOpen(!openSub);
	};

	const handleClose = (event) => {
		setOpen(false);
		onClose(event);
	};

	let content = [
		{
			title: "Accueil",
			onClick: () => {
				history("/");
			},
		},
		{
			title: "La Fondation",
			onClick: () => {
				history("/foundation");
			},
		},
	];

	const centerContent = [
		{
			title: "Actualités",
			onClick: () => {
				history("/news");
			},
		},
		{
			title: "Projets",
			onClick: () => {
				history("/projects");
			},
		},
		{
			title: "Ressources",
			onClick: () => {
				history("/resources");
			},
		},
	];

	const bottomContent = [
		{
			title: "L'Equipe",
			onClick: () => {
				history("/team");
			},
		},
		{
			title: "Collaborer",
			onClick: () => {
				history("/joining");
			},
		},
		{
			title: "Nous Contacter",
			onClick: () => {
				history("/contact");
			},
		},
	];
	return (
		<div>
			<React.Fragment key={"top"}>
				<Drawer anchor={"top"} open={open} onClose={onClose}>
					<div className={classes.menu}>
						<List>
							{content.map((item, index) => (
								<ListItem
									button
									key={index}
									onClick={(event) => {
										item.onClick();
										handleClose(event);
									}}
								>
									<ListItemText
										primary={item.title}
										align="right"
									/>
								</ListItem>
							))}
							<ListItem button onClick={handleClick}>
								<ListItemText
									primary="L'Institut"
									align="right"
								/>
								{!openSub ? <ExpandLess /> : <ExpandMore />}
							</ListItem>
							<Collapse in={openSub} timeout="auto" unmountOnExit>
								<List>
									<ListItem
										button
										onClick={(event) => {
											history("/institute");
											handleClose(event);
										}}
									>
										<ListItemText
											className={classes.nested}
											primary="Présentation"
											align="right"
										/>
									</ListItem>
									<ListItem
										button
										onClick={(event) => {
											history("/performance");
											handleClose(event);
										}}
									>
										<ListItemText
											className={classes.nested}
											primary="Prestations"
											align="right"
										/>
									</ListItem>
								</List>
							</Collapse>
						</List>
						<Divider />
						<List>
							{centerContent.map((item, index) => (
								<ListItem
									button
									key={index}
									onClick={(event) => {
										item.onClick();
										handleClose(event);
									}}
								>
									<ListItemText
										primary={item.title}
										align="right"
									/>
								</ListItem>
							))}
						</List>
						<Divider />
						<List>
							{bottomContent.map((item, index) => (
								<ListItem
									button
									key={index}
									onClick={(event) => {
										item.onClick();
										handleClose(event);
									}}
								>
									<ListItemText
										primary={item.title}
										align="right"
									/>
								</ListItem>
							))}
						</List>
					</div>
				</Drawer>
			</React.Fragment>
		</div>
	);
}
export default MenuDrawer;
