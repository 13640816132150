import { Container, Grid, makeStyles, Typography } from "@material-ui/core";import { collection, getDocs } from "firebase/firestore";

import React, { useEffect, useState } from "react";
import HeaderPage from "../../components/Page/HeaderPage";
import SectionImageLeft from "../../components/Page/SectionImageLeft";
import SectionImageRight from "../../components/Page/SectionImageRight";
import { db } from "../../services/firebaseDatabase";

const useStyle = makeStyles((theme) => ({
	root: { marginBottom: theme.spacing(6), marginTop: theme.spacing(6) },
}));
/**
 * Main component for the formation page
 * @returns
 */
function WolfFencesPage() {
	const classes = useStyle();
	const [content, setContent] = useState([]);
	const fetchContent = async () => {
		const data = await getDocs(collection(db, "loup_cloture"));

		data.forEach((item) => {
			setContent((content) => content.concat(item.data()));
		});
	};
	useEffect(() => {
		fetchContent();

		// eslint-disable-next-line
	}, []);

	const cloture = content.find((c) => c.indice === "cloture");
	const contexte = content.find((c) => c.indice === "contexte");
	const objectif = content.find((c) => c.indice === "objectif");
	const methode = content.find((c) => c.indice === "methode");
	const ou = content.find((c) => c.indice === "ou");

	return (
		<>
			{content.length > 0 ? (
				<>
					<Container className={classes.root}>
						<Grid container spacing={8}>
							<HeaderPage element={cloture} />
							<SectionImageRight element={contexte} />
							<SectionImageLeft element={objectif} />
							<SectionImageRight element={methode} />
							<SectionImageLeft element={ou} />
						</Grid>
					</Container>
				</>
			) : (
				<Container className={classes.root}>
					<Grid container spacing={6}>
						<Grid item xs align="center">
							<Typography variant="h5">Chargement...</Typography>
						</Grid>
					</Grid>
				</Container>
			)}
		</>
	);
}

export default WolfFencesPage;
