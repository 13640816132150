import { Grid, makeStyles, Typography } from "@material-ui/core";import { collection, getDocs } from "firebase/firestore";

import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { db } from "../../services/firebaseDatabase";

const useStyle = makeStyles((theme) => ({
	link: { textDecoration: "none", color: theme.palette.primary.main },
}));

function ReferencesSection({ links }) {
	const classes = useStyle();
	const [docs, setDocs] = useState([]);
	const fetchDocuments = async () => {
		const data = await getDocs(collection(db, "documents"));

		data.forEach((item) => {
			if (links)
				links.forEach((link) => {
					if (item.id === link) {
						setDocs((docs) =>
							docs.concat({ id: item.id, data: item.data() })
						);
					}
				});
		});
	};
	useEffect(() => {
		fetchDocuments();
		// eslint-disable-next-line
	}, []);
	return (
		<Grid item container spacing={2}>
			{docs.length > 0 && (
				<>
					<Grid item>
						<Typography variant="h4">
							Documents/Références
						</Typography>
					</Grid>
					<Grid item container>
						{docs.map((doc, index) => (
							<Grid item xs={12}>
								<Typography
									className={classes.link}
									key={index}
									element={<Link />}
									to={`/resources/${doc.id}`}
								>
									- {doc.data.title}
								</Typography>
							</Grid>
						))}
					</Grid>
				</>
			)}
		</Grid>
	);
}

export default ReferencesSection;
