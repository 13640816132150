import {	Box,
	Container,
	Fab,
	Fade,
	Grid,
	Hidden,
	IconButton,
	makeStyles,
	Typography,
	Zoom,
} from "@material-ui/core";
import { KeyboardArrowDown } from "@material-ui/icons";
import YouTubeIcon from "@material-ui/icons/YouTube";
import React, { useState } from "react";
import { Link } from "react-router-dom";
import VisibilitySensor from "react-visibility-sensor";
import LightTooltip from "../../../components/LightToolTips";

const BACKGROUND = process.env.PUBLIC_URL + "/img/background.jpg";
const LOGO_FJML = process.env.PUBLIC_URL + "/img/LOGO_FJML.png";
const LOGO_IPRA = process.env.PUBLIC_URL + "/img/LOGO_IPRA2.png";

const homeStyle = makeStyles((theme) => ({
	header: {
		width: "100%",
		backgroundImage: `linear-gradient(45deg , rgba(0, 0, 0, 0.2) 30%, rgba(255,255, 255, 0.2) 90%) , url(${BACKGROUND})`,
		backgroundRepeat: "no-repeat",
		backgroundPosition: "center center",
		backgroundSize: "cover",
		backgroundAttachment: "fixed",
		minHeight: `calc(100vh - ${theme.mixins.toolbar.minHeight}px)`,
		paddingTop: theme.spacing(4),
		paddingBottom: theme.spacing(4),
	},

	title: {
		color: "#FFF",
	},
	imageZoom: {
		transition: "transform 300ms",
		"&:hover": {
			cursor: "grab",
			transform: "scale(1.05)",
		},
	},
	headerLogoFJML: {
		height: "300px",
		[theme.breakpoints.down("sm")]: {
			height: "150px",
			marginTop: theme.spacing(4),
			marginBottom: theme.spacing(4),
		},
	},
	headerLogoIPRA: {
		height: "150px",
		[theme.breakpoints.down("sm")]: {
			marginTop: theme.spacing(4),
			marginBottom: theme.spacing(4),
		},
	},

	headerTypo: {
		textAlign: "center",
		marginTop: theme.spacing(8),
		marginBottom: theme.spacing(8),
		color: theme.palette.secondary.light,
		fontStyle: "italic",
	},
	ipraTitle: { color: "#000", textDecoration: "none", fontStyle: "none" },
	scrollDown: {
		position: "absolute",
		bottom: theme.spacing(4),
		marginLeft: "auto",
		marginRight: "auto",
		left: 0,
		right: 0,
		textAlign: "center",
	},
	fabScrollDown: {
		background: `linear-gradient(45deg, ${theme.palette.primary.main} 30%, ${theme.palette.primary.dark} 90%)`,
		color: theme.palette.primary.light,
	},
	icon: {
		fontSize: theme.spacing(12),
		color: theme.palette.secondary.main,
	},
}));

/**
 * Main component for the homepage
 * @returns
 */
function HomepageHeader() {
	const classes = homeStyle();

	const [visible, setVisible] = useState(false);

	const handleClick = (event) => {
		window.scrollTo({ top: 1016, behavior: "smooth" });
	};

	const onChange = (isVisible) => {
		setVisible(true);
	};

	return (
		<VisibilitySensor onChange={onChange}>
			<Grid container className={classes.header} alignItems="center">
				<Container>
					<Grid container>
						<Grid
							item
							container
							xs
							justifyContent="center"
							alignItems="center"
							className={classes.imageZoom}
						>
							<Link to="/foundation">
								<Fade in={visible} timeout={2000}>
									<Box>
										<img
											className={classes.headerLogoFJML}
											src={LOGO_FJML}
											alt=""
											draggable={false}
										/>
									</Box>
								</Fade>
							</Link>
						</Grid>
						<Grid
							item
							container
							xs
							align="center"
							alignItems="center"
							className={classes.imageZoom}
						>
							<Link to="/institute" className={classes.ipraTitle}>
								<Fade in={visible} timeout={2000}>
									<img
										className={classes.headerLogoIPRA}
										src={LOGO_IPRA}
										alt=""
										draggable={false}
									/>
								</Fade>
								<Fade in={visible} timeout={2000}>
									<Typography
										variant="h5"
										className={classes.ipraTitle}
									>
										Institut pour la Promotion et la
										Recherche sur les Animaux de protection
									</Typography>
								</Fade>
							</Link>
						</Grid>
					</Grid>
					<Grid item>
						<Typography variant="h6" className={classes.headerTypo}>
							POUR LA CONNAISSANCE DU LOUP DANS LE SYSTEME
							PASTORAL ET L’AMÉLIORATION DE LA PROTECTION DES
							TROUPEAUX
						</Typography>
					</Grid>
					<Grid
						item
						container
						direction="row"
						justifyContent="center"
						alignItems="center"
					>
						<Grid item>
							<LightTooltip title="Teaser">
								<IconButton
									onClick={() =>
										window.open(
											"https://www.youtube.com/watch?v=E5KtPT5MsiI&ab_channel=InstitutIPRA-Jean-MarcLandry-FJML"
										)
									}
								>
									<YouTubeIcon className={classes.icon} />
								</IconButton>
							</LightTooltip>
						</Grid>
					</Grid>
					<Hidden smDown>
						<Zoom in={true}>
							<div
								onClick={handleClick}
								role="presentation"
								className={classes.scrollDown}
							>
								<Fab
									className={classes.fabScrollDown}
									size="medium"
									aria-label="Voir la suite"
								>
									<KeyboardArrowDown />
								</Fab>
							</div>
						</Zoom>
					</Hidden>
				</Container>
			</Grid>
		</VisibilitySensor>
	);
}

export default HomepageHeader;
