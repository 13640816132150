import { collection, getDocs } from "firebase/firestore";import React, { useEffect, useState } from "react";
import { db } from "../../../services/firebaseDatabase";
import DocumentsList from "../components/DocumentsList";
/**
 * Main component for the News page
 * @returns
 */
function BibliographiePage() {
	const [loading, setLoading] = useState(true);
	const [docs, setDocs] = useState([]);
	const fetchDocuments = async () => {
		const data = await getDocs(collection(db, "documents"));

		data.forEach((item) => {
			if (
				item.data().category === "bibliographie" &&
				item.data().isPublished === true
			) {
				setDocs((docs) =>
					docs.concat({ id: item.id, data: item.data() })
				);
			}
		});
		setLoading(false);
	};
	useEffect(() => {
		fetchDocuments();
		// eslint-disable-next-line
	}, []);

	return (
		<DocumentsList
			documents={docs}
			titre="Bibliographie"
			loading={loading}
		/>
	);
}

export default BibliographiePage;
