import * as React from "react";import { Menu, useResourceDefinitions } from "react-admin";

const MyMenu = ({ onMenuClick }) => {
	// const isXSmall = useMediaQuery((theme) => theme.breakpoints.down("xs"));
	const resourcesDefinitions = useResourceDefinitions();
	const resources = Object.keys(resourcesDefinitions).map(
		(name) => resourcesDefinitions[name]
	);
	return (
		<Menu>
			<Menu.DashboardItem onClick={onMenuClick} />
			{resources.map((resource) => (
				<Menu.ResourceItem name={resource.name} />
			))}
		</Menu>
	);
};

export default MyMenu;
