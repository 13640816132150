import { Container, Grid, makeStyles, Typography } from "@material-ui/core";import React from "react";
import HeaderPage from "../../../components/Page/HeaderPage";
import NewsTeaser from "../../../components/Teaser/NewsTeaser";

const useStyle = makeStyles((theme) => ({
	root: { marginBottom: theme.spacing(6), marginTop: theme.spacing(6) },
}));
/**
 * Main component for the News page
 * @returns
 */
function NewsList({ news }) {
	const classes = useStyle();

	news.sort(function (a, b) {
		return new Date(b.data.published) - new Date(a.data.published);
	});

	return (
		<Container className={classes.root}>
			<HeaderPage element={{ title: "Actualités" }} />
			<Grid
				container
				direction="row"
				justifyContent="space-around"
				alignItems="center"
			>
				{news.length > 0 ? (
					news.map((oneNews, index) => {
						return (
							<Grid item key={index}>
								<NewsTeaser news={oneNews} />
							</Grid>
						);
					})
				) : (
					<Typography>Chargement...</Typography>
				)}
			</Grid>
		</Container>
	);
}

export default NewsList;
