import { Grid, makeStyles, Paper, Typography } from "@material-ui/core";import React from "react";
import { useNavigate } from "react-router-dom";
import calculateDate from "../../utils/dateToText";

const PLACEHOLDER = process.env.PUBLIC_URL + "/img/image-placeholder.jpg";

const useStyle = makeStyles((theme) => ({
	paper: {
		width: "400px",
		height: "450px",
		marginTop: theme.spacing(4),
		marginBottom: theme.spacing(4),
		transition: "transform 300ms",
		"&:hover": {
			cursor: "grab",
			transform: "scale(1.02)",
		},
		[theme.breakpoints.down("sm")]: {
			width: "350px",
		},
	},
	image: {
		width: "400px",
		height: "250px",
		objectFit: "cover",
		borderTopRightRadius: "5px",
		borderTopLeftRadius: "5px",
		[theme.breakpoints.down("sm")]: {
			width: "350px",
		},
	},
	// paperContent: { width: "350px", height: "200px" },
	body: {
		height: "200px",
		width: "400px",
		padding: theme.spacing(2),
		[theme.breakpoints.down("sm")]: {
			width: "350px",
		},
	},
	title: {
		width: "375px",
		display: "-webkit-box",
		WebkitLineClamp: 2,
		WebkitBoxOrient: "vertical",
		overflow: "hidden",
		textOverflow: "ellipsis",
		[theme.breakpoints.down("sm")]: {
			width: "325px",
		},
	},
}));

function NewsTeaser({ news }) {
	const classes = useStyle();
	var history = useNavigate();
	const content = news.data;
	return (
		<Paper
			elevation={3}
			className={classes.paper}
			onClick={() => {
				history(`/news/${news.id}`);
			}}
		>
			<div className={classes.paperContent}>
				{content.pictures ? (
					<img
						className={classes.image}
						src={content.pictures.src}
						alt={content.pictures.title}
					/>
				) : (
					<img
						className={classes.image}
						src={PLACEHOLDER}
						alt={"Placeholder"}
					/>
				)}
			</div>
			<Grid
				container
				direction="column"
				justifyContent="space-between"
				className={classes.body}
			>
				<Grid item>
					<Typography variant="h4" className={classes.title}>
						{content.title}
					</Typography>
				</Grid>
				<Grid item>
					<Typography className={classes.title}>
						{content.teaser}
					</Typography>
				</Grid>
				<Grid item>
					<Typography className={classes.title}>
						<i>{calculateDate(content.published)}</i>
					</Typography>
				</Grid>
			</Grid>
		</Paper>
	);
}

export default NewsTeaser;
