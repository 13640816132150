import React from "react";import { Admin, Resource } from "react-admin";
import {
	FirebaseAuthProvider,
	FirebaseDataProvider,
} from "react-admin-firebase";
import { firebaseConfig } from "../../services/firebaseConfig";
import theme from "../../theme";
import { customRoutes } from "./components/CustomRoutes";
import Dashboard from "./components/Dashboard";
import MyLayout from "./components/Layout";
import { MyLoginPage } from "./components/Login";
import { ContactEdit, ContactList } from "./resources/Contact";
import {
	DocumentsCreate,
	DocumentsEdit,
	DocumentsList,
} from "./resources/Documents";
import {
	EmployeeCreate,
	EmployeeEdit,
	EmployeeList,
} from "./resources/Employee";
import { NewsCreate, NewsEdit, NewsList } from "./resources/News";
import { PageEdit, PageList } from "./resources/Page";
import {
	PartenairesCreate,
	PartenairesEdit,
	PartenairesList,
} from "./resources/Partenaires";
import { ProjetsCreate, ProjetsEdit, ProjetsList } from "./resources/Projets";
import { VideoCreate, VideoEdit, VideoList } from "./resources/Videotheque";
import { YoutubeEdit, YoutubeList } from "./resources/Youtube";

const options = {};

const dataProvider = FirebaseDataProvider(firebaseConfig, options);
const firebaseAuthProvider = FirebaseAuthProvider(firebaseConfig);

function Administration() {
	return (
		<Admin
			basename="/administration"
			loginPage={MyLoginPage}
			authProvider={firebaseAuthProvider}
			dataProvider={dataProvider}
			title="Administration"
			theme={theme}
			layout={MyLayout}
			dashboard={Dashboard}
			customRoutes={customRoutes}
		>
			<Resource
				name="news"
				list={NewsList}
				create={NewsCreate}
				edit={NewsEdit}
			/>
			<Resource
				name="documents"
				list={DocumentsList}
				create={DocumentsCreate}
				edit={DocumentsEdit}
			/>
			<Resource
				name="videotheque"
				list={VideoList}
				create={VideoCreate}
				edit={VideoEdit}
			/>
			<Resource
				name="partenaires"
				list={PartenairesList}
				create={PartenairesCreate}
				edit={PartenairesEdit}
			/>
			<Resource
				name="sites"
				list={PartenairesList}
				create={PartenairesCreate}
				edit={PartenairesEdit}
			/>
			<Resource
				name="youtube"
				list={YoutubeList}
				// create={YoutubeCreate}
				edit={YoutubeEdit}
			/>
			<Resource
				name="IPRA"
				list={PageList}
				// create={PageCreate}
				edit={PageEdit}
			/>
			<Resource
				name="FJML"
				list={PageList}
				// create={PageCreate}
				edit={PageEdit}
			/>
			<Resource
				name="equipe"
				list={EmployeeList}
				create={EmployeeCreate}
				edit={EmployeeEdit}
			/>
			<Resource
				name="prestations"
				list={PageList}
				// create={PageCreate}
				edit={PageEdit}
			/>
			<Resource
				name="projets"
				list={ProjetsList}
				create={ProjetsCreate}
				edit={ProjetsEdit}
			/>
			<Resource
				name="Proxyloup"
				list={PageList}
				// create={PageCreate}
				edit={PageEdit}
			/>
			<Resource
				name="loup_cloture"
				list={PageList}
				// create={PageCreate}
				edit={PageEdit}
			/>
			<Resource
				name="Daphnis"
				list={PageList}
				// create={PageCreate}
				edit={PageEdit}
			/>
			<Resource
				name="Canovis"
				list={PageList}
				// create={PageCreate}
				edit={PageEdit}
			/>
			<Resource
				name="pastoralisme"
				list={PageList}
				// create={PageCreate}
				edit={PageEdit}
			/>
			<Resource
				name="contact"
				list={ContactList}
				// create={ContactCreate}
				edit={ContactEdit}
			/>
			<Resource
				name="collaborer"
				list={ContactList}
				// create={ContactCreate}
				edit={ContactEdit}
			/>
			<Resource
				name="mentions"
				list={ContactList}
				// create={ContactCreate}
				edit={ContactEdit}
			/>
		</Admin>
	);
}

export default Administration;
