import { Grid, makeStyles, Paper, Typography } from "@material-ui/core";import React from "react";
import { useNavigate } from "react-router-dom";

const useStyle = makeStyles((theme) => ({
	paper: {
		width: "400px",
		height: "350px",
		marginTop: theme.spacing(4),
		marginBottom: theme.spacing(4),
		transition: "transform 300ms",
		"&:hover": {
			cursor: "grab",
			transform: "scale(1.02)",
		},
		[theme.breakpoints.down("sm")]: {
			width: "350px",
		},
	},
	image: {
		width: "400px",
		height: "250px",
		objectFit: "cover",
		borderTopRightRadius: "5px",
		borderTopLeftRadius: "5px",
		[theme.breakpoints.down("sm")]: {
			width: "350px",
		},
	},
	// paperContent: { width: "350px", height: "200px" },
	body: {
		height: "100px",
		width: "400px",
		padding: theme.spacing(2),
		[theme.breakpoints.down("sm")]: {
			width: "350px",
		},
	},
	title: {
		width: "380px",
		display: "-webkit-box",
		WebkitLineClamp: 1,
		WebkitBoxOrient: "vertical",
		overflow: "hidden",
		textOverflow: "ellipsis",
		[theme.breakpoints.down("sm")]: {
			width: "330px",
		},
	},
}));

function MediaTeaser({ title, image, link }) {
	const classes = useStyle();
	var history = useNavigate();

	return (
		<Paper
			elevation={3}
			className={classes.paper}
			onClick={() => {
				history(link);
			}}
		>
			<div>
				<img className={classes.image} src={image} alt={image} />
			</div>
			<Grid
				container
				direction="column"
				justifyContent="space-between"
				className={classes.body}
			>
				<Grid item>
					<Typography variant="h4" className={classes.title}>
						{title}
					</Typography>
				</Grid>
			</Grid>
		</Paper>
	);
}

export default MediaTeaser;
