import {	Button,
	Container,
	Grid,
	makeStyles,
	Typography,
} from "@material-ui/core";
import React from "react";
import { useNavigate } from "react-router-dom";
import NewsTeaser from "../../../components/Teaser/NewsTeaser";

const useStyle = makeStyles((theme) => ({
	body: {
		paddingTop: theme.spacing(6),
		paddingBottom: theme.spacing(6),
		minHeight: "60vh",
		backgroundColor: theme.palette.primary.light,
	},
	title: { textAlign: "center" },
	button: {
		background: `linear-gradient(45deg, ${theme.palette.primary.main} 30%, ${theme.palette.primary.dark} 90%)`,
		color: theme.palette.secondary.main,
	},
}));

/**
 * Component to display professional homepage
 */
function HomepageNews({ news }) {
	const classes = useStyle();
	const history = useNavigate();

	news.sort(function (a, b) {
		return new Date(b.data.published) - new Date(a.data.published);
	});

	return (
		<div className={classes.body}>
			<Container>
				<Grid container>
					<Grid item xs={12}>
						<Typography variant="h4" className={classes.title}>
							Dernières actualités
						</Typography>
					</Grid>
					<Grid
						container
						direction="row"
						justifyContent="space-around"
						alignItems="center"
					>
						{news.length > 0 ? (
							news.slice(0, 3).map((oneNews, index) => {
								return (
									<Grid item key={index}>
										<NewsTeaser news={oneNews} />
									</Grid>
								);
							})
						) : (
							<Typography>
								Il n'y a pas d'actualités pour le moment.
							</Typography>
						)}
					</Grid>
					<Grid item xs={12} align="center">
						<Button
							className={classes.button}
							variant="contained"
							onClick={() => history("/news")}
						>
							Voir plus
						</Button>
					</Grid>
				</Grid>
			</Container>
		</div>
	);
}

export default HomepageNews;
