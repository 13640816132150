import { makeStyles } from "@material-ui/core";
import React from "react";

const useStyle = makeStyles((theme) => ({
	image: { filter: "drop-shadow(4px 4px 8px #000)", borderRadius: "10px" },
}));

function Image({ src, alt, width }) {
	const classes = useStyle();
	return (
		<img
			className={classes.image}
			src={src}
			alt={alt}
			draggable={false}
			width={width}
		/>
	);
}

export default Image;
