import React from "react";import {
	Create,
	Datagrid,
	Edit,
	ImageField,
	ImageInput,
	List,
	SimpleForm,
	TextField,
	TextInput,
} from "react-admin";

export function ProjetsList(props) {
	return (
		<List {...props}>
			<Datagrid rowClick="edit">
				<TextField source="order" label="Ordre de priorité" />
				<TextField source="title" label="Titre" />
				<TextField source="body" label="Contenu" />
			</Datagrid>
		</List>
	);
}

export const ProjetsCreate = (props) => {
	return (
		<Create title="Nouveau Document" {...props}>
			<SimpleForm>
				<TextInput
					source="order"
					label="Ordre de priorité"
					resettable
					fullWidth
				/>
				<TextInput source="title" label="Titre" resettable />
				<TextInput source="body" label="Contenu" multiline />
				<TextInput
					source="link"
					label="Lien vers la page du projet (DEV)"
				/>

				<ImageInput
					source="pictures"
					label="Image de présentation"
					accept="image/*"
				>
					<ImageField source="src" title="title" />
				</ImageInput>
			</SimpleForm>
		</Create>
	);
};

export const ProjetsEdit = (props) => {
	return (
		<Edit title="Modifier" {...props} undoable={false}>
			<SimpleForm>
				<TextInput
					source="order"
					label="Ordre de priorité"
					resettable
					fullWidth
				/>
				<TextInput source="title" label="Titre" />
				<TextInput source="body" label="Contenu" />
				<TextInput
					source="link"
					label="Lien vers la page du projet (DEV)"
				/>

				<ImageInput
					source="pictures"
					label="Related pictures"
					accept="image/*"
				>
					<ImageField source="src" title="title" />
				</ImageInput>
			</SimpleForm>
		</Edit>
	);
};
