import { Container, Grid, makeStyles, Typography } from "@material-ui/core";import { collection, getDocs } from "firebase/firestore";

import React, { useEffect, useState } from "react";
import HeaderPage from "../../components/Page/HeaderPage";
import ProjectsTeaser from "../../components/Teaser/ProjectsTeaser";
import { db } from "../../services/firebaseDatabase";

const useStyle = makeStyles((theme) => ({
	root: { marginBottom: theme.spacing(6), marginTop: theme.spacing(6) },
}));

/**
 * Main component for the projects page
 * @returns
 */
function ProjectsPage() {
	const classes = useStyle();

	const [content, setContent] = useState([]);
	const fetchContent = async () => {
		const data = await getDocs(collection(db, "projets"));
		data.forEach((item) => {
			setContent((content) => content.concat(item.data()));
		});
	};
	useEffect(() => {
		fetchContent();

		//eslint-disable-next-line
	}, []);

	content.sort(function (a, b) {
		return a.order - b.order;
	});

	return (
		<Container className={classes.root}>
			<HeaderPage
				element={{
					title: "Projects de recherche",
					body: "Dans une approche globale de la problématique prédateurs-élevages, l’institut mène des projets complémentaires pour explorer différentes pistes d'améliorations.",
				}}
			/>
			<Grid
				container
				direction="row"
				justifyContent="space-around"
				alignItems="center"
			>
				{content.length > 0 ? (
					content.map((projet, index) => {
						return (
							<Grid item key={index}>
								<Grid item>
									<ProjectsTeaser
										title={projet.title}
										body={projet.body}
										image={projet.pictures?.src}
										link={projet.link}
									/>
								</Grid>
							</Grid>
						);
					})
				) : (
					<Typography>Chargement...</Typography>
				)}
			</Grid>
		</Container>
	);
}

export default ProjectsPage;
