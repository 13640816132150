export const firebaseConfig = {
	// apiKey: process.env.REACT_APP_FIREBASE_API_KEY || "mock_key",
	// authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
	// databaseURL: process.env.REACT_APP_FIREBASE_DATABASE_URL,
	// projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
	// storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
	// messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER,
	// appId: process.env.REACT_APP_FIREBASE_APP_ID,
	// measurementId: process.env.REACT_APP_FIREBASE_MEASUREMENT_ID
	apiKey: "AIzaSyAqEBd1ab-rgxRwZ_z9R4xDum6bErlQuyo",
	authDomain: "test-1eb7c.firebaseapp.com",
	databaseURL: "https://test-1eb7c-default-rtdb.firebaseio.com",
	projectId: "test-1eb7c",
	storageBucket: "test-1eb7c.appspot.com",
	messagingSenderId: "996309964699",
	appId: "1:996309964699:web:d51bfe4219055e03ae733e",
	measurementId: "G-2FT2YX486B",
};
