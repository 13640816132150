import { Grid, makeStyles, Typography } from "@material-ui/core";import React from "react";
const useStyle = makeStyles((theme) => ({
	root: {
		marginTop: theme.spacing(4),
		marginBottom: theme.spacing(4),
	},
	name: {
		color: theme.palette.primary.main,
		marginBottom: theme.spacing(3),
	},
	img: {
		borderRadius: "50%",
		filter: "drop-shadow(4px 4px 8px #000)",
		objectFit: "cover",
	},
}));

function EmployeeTeaser({ image, name, body }) {
	const classes = useStyle();
	return (
		<Grid
			container
			className={classes.root}
			direction="row"
			justifyContent="center"
			alignItems="center"
		>
			<Grid container item xs={4} justifyContent="center">
				<img
					className={classes.img}
					src={image}
					alt={image}
					width="300px"
					height="300px"
					draggable={false}
				/>
			</Grid>
			<Grid item xs={8}>
				<Typography className={classes.name} variant="h6">
					{name}
				</Typography>
				<Typography
					dangerouslySetInnerHTML={{
						__html: body,
					}}
				></Typography>
			</Grid>
		</Grid>
	);
}

export default EmployeeTeaser;
