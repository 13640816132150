import { RichTextInput } from "ra-input-rich-text";import React from "react";
import {
	BooleanField,
	BooleanInput,
	ChipField,
	Create,
	Datagrid,
	Edit,
	ImageField,
	ImageInput,
	List,
	NumberField,
	NumberInput,
	ReferenceArrayField,
	ReferenceArrayInput,
	required,
	SelectArrayInput,
	SimpleForm,
	SingleFieldList,
	TextField,
	TextInput,
} from "react-admin";
import { PostPanelVideo } from "../components/PostPanel";

export function VideoList(props) {
	return (
		<List {...props} sort={{ field: "number", order: "ASC" }}>
			<Datagrid rowClick="edit" expand={<PostPanelVideo />}>
				<BooleanField source="published" label="Publié" />
				<NumberField source="number" label="Numéro de séquence" />
				<TextField source="title" label="Titre" />
				<ReferenceArrayField
					label="Documents/Références"
					reference="documents"
					source="documents_id"
				>
					<SingleFieldList>
						<ChipField source="title" />
					</SingleFieldList>
				</ReferenceArrayField>
			</Datagrid>
		</List>
	);
}

export const VideoCreate = (props) => {
	return (
		<Create title="Nouveau Document" {...props}>
			<SimpleForm>
				<BooleanInput
					label="Publié"
					source="published"
					initialValue={true}
				/>

				<NumberInput
					source="number"
					label="Numéro de séquence"
					validate={[required()]}
					fullWidth
				/>

				<TextInput
					source="title"
					label="Titre"
					resettable
					validate={[required()]}
					fullWidth
				/>
				<ImageInput
					source="pictures"
					label="Image de l'aperçu"
					accept="image/*"
				>
					<ImageField source="src" title="title" />
				</ImageInput>
				<RichTextInput source="description" label="Description" />
				<ReferenceArrayInput
					label="Documents/Références"
					source="documents_id"
					reference="documents"
					sort={{ field: "published", order: "DESC" }}
					allowEmpty
					fullWidth
				>
					<SelectArrayInput optionText="title" />
				</ReferenceArrayInput>
			</SimpleForm>
		</Create>
	);
};

export const VideoEdit = (props) => {
	return (
		<Edit title="Modifier le document" {...props} undoable={false}>
			<SimpleForm>
				<BooleanInput
					label="Publié"
					source="published"
					initialValue={true}
				/>
				<NumberInput
					source="number"
					label="Numéro de séquence"
					validate={[required()]}
					fullWidth
				/>
				<TextInput
					source="title"
					label="Titre"
					resettable
					validate={[required()]}
					fullWidth
				/>
				<ImageInput
					source="pictures"
					label="Image de l'aperçu"
					accept="image/*"
				>
					<ImageField source="src" title="title" />
				</ImageInput>
				<RichTextInput source="description" label="Description" />
				<ReferenceArrayInput
					label="Documents/Références"
					source="documents_id"
					reference="documents"
					sort={{ field: "published", order: "DESC" }}
					allowEmpty
					fullWidth
				>
					<SelectArrayInput optionText="title" fullWidth />
				</ReferenceArrayInput>
			</SimpleForm>
		</Edit>
	);
};
