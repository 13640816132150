import { Container, Divider, Grid, makeStyles } from "@material-ui/core";import { collection, getDocs } from "firebase/firestore";

import React, { useEffect, useState } from "react";
import HeaderPage from "../../components/Page/HeaderPage";
import SectionHeader from "../../components/Page/SectionHeader";
import EmployeeTeaser from "../../components/Teaser/EmployeeTeaser";
import { db } from "../../services/firebaseDatabase";

const useStyle = makeStyles((theme) => ({
	root: { marginBottom: theme.spacing(6), marginTop: theme.spacing(6) },
}));
function TeamPage() {
	const classes = useStyle();
	const [conseil, setConseil] = useState([]);
	const [direction, setDirection] = useState([]);
	const [terrain, setTerrain] = useState([]);
	const [externe, setExterne] = useState([]);

	const [jura, setJura] = useState([]);
	const [sudalpes, setSudalpes] = useState([]);
	const [nordalpes, setNordalpes] = useState([]);

	const fetchContent = async () => {
		const data = await getDocs(collection(db, "equipe"));

		data.forEach((item) => {
			switch (item.data().category) {
				case "conseil":
					setConseil((conseil) => conseil.concat(item.data()));
					break;
				case "direction":
					setDirection((direction) => direction.concat(item.data()));
					break;
				case "terrain":
					setTerrain((terrain) => terrain.concat(item.data()));
					break;
				case "externe":
					setExterne((externe) => externe.concat(item.data()));
					break;
				case "jura":
					setJura((jura) => jura.concat(item.data()));
					break;
				case "sudalpes":
					setSudalpes((sudalpes) => sudalpes.concat(item.data()));
					break;
				case "nordalpes":
					setNordalpes((nordalpes) => nordalpes.concat(item.data()));
					break;
				default:
					break;
			}
		});
	};
	useEffect(() => {
		fetchContent();

		// eslint-disable-next-line
	}, []);

	return (
		<Container className={classes.root}>
			<HeaderPage element={{ title: "L'Equipe" }} />
			{conseil.length > 0 && (
				<>
					<Grid container spacing={8}>
						<SectionHeader
							element={{ title: "Conseil de Fondation" }}
						/>

						{conseil.map((c) => (
							<EmployeeTeaser
								name={c.name}
								body={c.body}
								image={c.pictures.src}
							/>
						))}
					</Grid>
				</>
			)}
			{direction.length > 0 && (
				<>
					<Divider className={classes.root} />
					<Grid container spacing={8}>
						<SectionHeader
							element={{
								title: "Direction & direction des recherches",
							}}
						/>

						{direction.map((c) => (
							<EmployeeTeaser
								name={c.name}
								body={c.body}
								image={c.pictures.src}
							/>
						))}
					</Grid>
				</>
			)}
			{terrain.length > 0 && (
				<>
					<Divider className={classes.root} />
					<Grid container spacing={8}>
						<SectionHeader
							element={{ title: "Equipe de terrain" }}
						/>
						{terrain.map((c) => (
							<EmployeeTeaser
								name={c.name}
								body={c.body}
								image={c.pictures.src}
							/>
						))}
					</Grid>
				</>
			)}
			{externe.length > 0 && (
				<>
					<Divider className={classes.root} />
					<Grid container spacing={8}>
						<SectionHeader
							element={{ title: "Collaborateurs externes" }}
						/>

						{externe.map((c) => (
							<EmployeeTeaser
								name={c.name}
								body={c.body}
								image={c.pictures.src}
							/>
						))}
					</Grid>
				</>
			)}
			{jura.length > 0 && (
				<>
					<Divider className={classes.root} />
					<Grid container spacing={8}>
						<SectionHeader element={{ title: "Arc jurassien" }} />

						{jura.map((c) => (
							<EmployeeTeaser
								name={c.name}
								body={c.body}
								image={c.pictures.src}
							/>
						))}
					</Grid>
				</>
			)}
			{nordalpes.length > 0 && (
				<>
					<Divider className={classes.root} />
					<Grid container spacing={8}>
						<SectionHeader element={{ title: "Alpes du Nord" }} />

						{nordalpes.map((c) => (
							<EmployeeTeaser
								name={c.name}
								body={c.body}
								image={c.pictures.src}
							/>
						))}
					</Grid>
				</>
			)}
			{sudalpes.length > 0 && (
				<>
					<Divider className={classes.root} />
					<Grid container spacing={8}>
						<SectionHeader element={{ title: "Alpes du Sud" }} />

						{sudalpes.map((c) => (
							<EmployeeTeaser
								name={c.name}
								body={c.body}
								image={c.pictures.src}
							/>
						))}
					</Grid>
				</>
			)}
		</Container>
	);
}

export default TeamPage;
