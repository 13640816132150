import { Typography } from "@material-ui/core";import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import * as React from "react";
import { Title } from "react-admin";
const Dashboard = () => (
	<Card>
		<Title title="Bienvenue" />
		<CardContent>
			<Typography variant="h5" style={{ marginBottom: "24px" }}>
				Bienvenue sur la page d'Administration
			</Typography>
			<Typography>
				<i>Compte Admin </i>: Ajoutez, supprimez, modifez ou encore
				visualisez les ressources du site IPRA-FJML. <br />
			</Typography>
		</CardContent>
	</Card>
);

export default Dashboard;
