import {	Grid,
	isWidthUp,
	makeStyles,
	Typography,
	withWidth,
} from "@material-ui/core";
import React from "react";
import ReferencesSection from "./ReferencesSection";

const useStyle = makeStyles((theme) => ({
	title: {
		fontSize: "6rem",
		fontWeight: "100",
		[theme.breakpoints.down("sm")]: {
			fontSize: "3rem",
		},
	},
}));

function HeaderPage({ element, width }) {
	const classes = useStyle();
	return (
		<>
			{element && (
				<Grid item container spacing={6} id={element.title}>
					{element.pictures ? (
						<>
							<Grid
								item
								container
								md={6}
								xs={12}
								alignItems={
									isWidthUp("md", width)
										? "flex-end"
										: "center"
								}
								justifyContent="center"
								direction="column"
							>
								<Typography className={classes.title}>
									{element.title}
								</Typography>
							</Grid>
							<Grid
								item
								container
								md={6}
								xs={12}
								alignItems={
									isWidthUp("md", width)
										? "flex-start"
										: "center"
								}
								justifyContent="center"
								direction="column"
							>
								<img
									src={element.pictures.src}
									alt={element.pictures.title}
									width="50%"
									draggable={false}
								/>
							</Grid>
						</>
					) : (
						<Grid item container xs={12} justifyContent="center">
							{element.title && (
								<Typography className={classes.title}>
									{element.title}
								</Typography>
							)}
						</Grid>
					)}

					<Grid item xs={12}>
						{element.body && (
							<Typography
								align="justify"
								dangerouslySetInnerHTML={{
									__html: element.body,
								}}
							></Typography>
						)}
					</Grid>
					<ReferencesSection links={element.documents_id} />
				</Grid>
			)}
		</>
	);
}

export default withWidth()(HeaderPage);
