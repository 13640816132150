export const PostPanel = ({ id, record, resource }) => (
	<>
		<div dangerouslySetInnerHTML={{ __html: record.body }} />
	</>
);

export const PostPanelVideo = ({ id, record, resource }) => (
	<>
		<div dangerouslySetInnerHTML={{ __html: record.description }} />
	</>
);
