import { Grid, makeStyles, Typography } from "@material-ui/core";
import React from "react";
import Image from "../Image";
import ReferencesSection from "./ReferencesSection";

const useStyle = makeStyles((theme) => ({
	root: { marginBottom: theme.spacing(6), marginTop: theme.spacing(6) },
	legend: {
		marginTop: theme.spacing(1),
		color: theme.palette.primary.main,
		fontStyle: "italic",
		textAlign: "center",
	},
	body: {
		fontSize: "1.3em",
		textAlign: "right",
		width: "100%",
	},
}));

function SectionImageTop({ element }) {
	const classes = useStyle();
	return (
		<>
			{element && (
				<Grid item container spacing={6} id={element.title}>
					{element.pictures && (
						<Grid item xs={12}>
							<Image
								src={element.pictures.src}
								alt={element.pictures.title}
								width="100%"
							/>
							{element.legend && (
								<Typography className={classes.legend}>
									{element.legend}
								</Typography>
							)}
						</Grid>
					)}
					{element.body && (
						<Grid item xs={12}>
							<Typography
								align="justify"
								dangerouslySetInnerHTML={{
									__html: element.body,
								}}
							></Typography>
						</Grid>
					)}
					<ReferencesSection links={element.documents_id} />
				</Grid>
			)}
		</>
	);
}

export default SectionImageTop;
